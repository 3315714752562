<script>
export default {
  name: "UploadCapturedImage",
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      errors: []
    }
  },
  computed: {
    modelValue: {
      get() { return this.value; },
      set(value) { this.$emit('input', value); }
    }
  },
  methods: {
    async onChangeInFile(event) {
      this.errors = [];
      const validation = (file) => {
        const error = [];
        if (!['image/png','image/jpeg'].includes(file.type)) {
          error.push('The selected file type is not allowed. Only PNG and JPEG is supported.');
        }
        if ((file.size / (1024 * 1024)) > 10) {
          error.push("The file exceed the maximum allowed limit (10 MB).")
        }
        return error;
      }
      const readFile = (file) => {
        return new Promise(resolve => {
          const FileAPI = new File([file.name], file);
          const ReaderAPI = new FileReader();
          ReaderAPI.onload = (event) => { resolve(event.target.result) };
          ReaderAPI.readAsDataURL(file);
        });
      }
      if (event.target.files.length) {
        const file = event.target.files[0];
        this.errors = validation(file)
        if (!this.errors.length) {
          const screenShot = await readFile(file);
          this.$store.commit('setHelpDeskFormScreenShot', screenShot);
          // try {
          //   const screenShot = await readFile(file);
          //   this.$emit('input', screenShot);
          //   // this.$store.commit('setHelpDeskFormScreenShot', screenShot);
          // }catch (error) {
          //   console.log("Upload image error" + error.message);
          // }
        }
      }
      event.target.value = "";
    }
  }
}
</script>

<template>
  <div>
    <div class="helpdesk-manual-upload-image-wrapper" v-if="visible">
      <label>
        <span class="btn btn-outline-primary btn-block mx-0">
          <i class="fa fa-upload mr-3" aria-hidden="true"></i>
          Upload image
        </span>
        <input @change="onChangeInFile" type="file" accept="image/png,image/jpeg" />
      </label>
      <div class="invalid-feedback d-block" v-for="(error, errorIndex) in errors" :key="errorIndex">{{error}}</div>
    </div>
  </div>
</template>

<style scoped>

</style>