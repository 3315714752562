<template>
    <section class="o-mid-container">
        <div id="content-body">
            <div class="py-5 text-center w-100">
                <div class="mx-auto w-xxl w-auto-xs">
                    <div class="px-3">
                        <form name="form" @submit.prevent="loginForm">
                            <div class="form-group">
                                <input type="email"
                                v-model="form.email" 
                                id="email" name="email" 
                                class="form-control" placeholder="Email" required>
                            </div>
                            <div class="form-group">
                                <input type="password" 
                                v-model="form.password" 
                                id="password" name="password"  class="form-control" placeholder="password" required>
                                <span toggle="#password-field" @click="passwordShowHide" id="toggle-password" class="fa fa-fw fa-eye field-icon toggle-password"></span>
                            </div>
                            <b-overlay :show="buttonLoading" rounded="lg" opacity="0.6">
                                <button type="submit" class="btn primary">Sign in</button>
                            </b-overlay>
                        </form>
                        <div class="my-4">
                            <router-link :to="{name : 'forgot-password'}" class="text-primary _600">
                                Forgot password?
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <HelpDeskWidget />
    </section>
</template>
<script>
import { mapActions,mapGetters,mapState } from 'vuex';
import Api from '../../../service/api';
import HelpDeskWidget from "@/components/protected/HelpDesk/HelpDeskWidget.vue";
// import { setTimeout } from 'timers';

export default {
    components: {
      HelpDeskWidget
    },
    data () {
        return {
            form: {
                email: '',
                password: '',
                
            },
            login_status : false,
            remember_me : false,
            submitted: false,
            buttonLoading: false
        }
    },
    
    created() {
        this.checklogin();
    },
    
    computed: {
        ...mapGetters({
            isLoggedIn:'isLoggedIn',
            token:'getToken'
        }),
        // ...mapState('auth', ['isLoggedIn','token'])
    },

    mounted() {
        
    },

   
    methods: {
        passwordShowHide() {
            let pId = document.getElementById('toggle-password');
            const classes = pId.classList;
            classes.toggle("fa-eye-slash");
            let input = document.getElementById('password');
            if (input.type == "password") {
                input.type = "text";
            } else {
                input.type = "password";
            }  
        },
        checklogin(){
            // console.log('aaaa'+this.isLoggedIn)
            if (this.isLoggedIn) {
                this.$router.push('/dashboard')
            }
        },
        loginForm(e){
            this.submitted = true;
            this.login(this.form);
        },
        async login(params){
            this.login_status = true;
            this.buttonLoading = true;
            //var current_domain = window.location.hostname;
            return await Api.login(params)
            .then(response => {
                this.buttonLoading = false;
                if(response.status === 200){
                    if(response.data.status != '1'){
                        this.$swal({ title: response.data.error, type: "error" });
                        this.login_status = false;
                    } else {
                           this.$store.commit('setToken', response.data.data);

                           this.login_status = false;
                           this.$router.push({name : 'dashboard'});
                    }
                } else {
                    this.$swal.fire(
                        'Error',
                        'Something went wrong',
                        'error'
                    )
                }
            })
            .catch(e => {
                // console.log(e);
                this.buttonLoading = false;
                this.$swal({ title: e.response.data.message, type: "warning" });
            })
        }
    }
}
</script>

