<template>
    <div v-if="show_div_type" id="aside" class="app-aside fade box-shadow-x nav-expand white fixed toggle-expend-mobile" aria-hidden="true">
        <div class="sidenav modal-dialog dk white dark">
          <!-- sidenav top -->
            <div class="navbar lt">
              <!-- brand -->
              <a href="/" class="navbar-brand">
                    <span class="avatar w-100">
                        <img src="@/assets/images/logo-icon.png" alt="logo-icon">
                    </span>
              </a>
              <!-- / brand -->
            </div>
            <!-- Flex nav content -->
            <div class="flex hide-scroll not-expand">
              <div class="scroll">
                  <div class="nav-border b-primary" data-nav>
                      <ul class="nav bg">
                          <li>
                              <router-link :to="{name : 'dashboard'}">
                                  <span class="nav-icon"><router-link :to="{name : 'dashboard'}"><i class="fa fa-dashboard"></i></router-link></span>
                              </router-link>
                          </li>
                          <li>
                              <router-link :to="{name : 'web-pages-applications'}">
                                  <span class="nav-icon"><router-link :to="{name : 'web-pages-applications'}"><i class="fa fa-desktop"></i></router-link></span>
                              </router-link>
                          </li>
                          <li>
                              <router-link :to="{name : 'activity-history'}">
                                  <span class="nav-icon"><router-link :to="{name : 'activity-history'}"><i class="fa fa-history"></i></router-link></span>
                              </router-link>
                          </li>
                          <li v-if="isSuperAdmin">
                              <router-link :to="{name : 'organizations'}">
                                  <span class="nav-icon"><router-link :to="{name : 'organizations'}"><i class="fa fa-building-o"></i></router-link></span>
                              </router-link>
                          </li>
                          <li>
                              <router-link :to="{name : 'global-calendar'}">
                                  <span class="nav-icon"><i class="fa fa-calendar"></i></span>
                              </router-link>
                          </li>
                          <li>
                            <a>
                                <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                <span class="nav-icon"><i class="fa fa-file-text"></i></span>
                            </a>
                            <ul class="nav-sub nav-sub-mobile">
                                <li>
                                    <router-link :to="{name : 'auto-generate-session'}">
                                        <span class="nav-text">Generate Session Notes</span>
                                    </router-link>
                                </li> 
                                <li>
                                    <router-link :to="{ name: 'sessions-queue' }">
                                        <span class="nav-text">Sessions Queue</span>
                                    </router-link>
                                </li> 
                                <li>
                                    <router-link :to="{name : 'auto-generate-session-import'}">
                                        <span class="nav-text">Session Data Import <br>(Kareo File)</span>
                                    </router-link>
                                </li> 
                                <!-- <li>
                                    <router-link :to="{name : 'session-report'}">
                                        <span class="nav-text">Session Report</span>
                                    </router-link>
                                </li> -->
                                <li>
                                    <router-link :to="{name : 'mass-goal-import'}">
                                        <span class="nav-text">Goal Import</span>
                                    </router-link>
                                </li>
                            </ul>
                            
                          </li>
                          <li>
                              <a>
                                  <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                  <span class="nav-icon"><i class="fa fa-cog"></i></span>
                              </a>
                                <ul class="nav-sub nav-sub-mobile">
                                    <li v-if="package_list">
                                        <router-link :to="{name : 'package'}">
                                            <span class="nav-text">Package</span>
                                        </router-link>
                                    </li>
                                    <li v-if="payers_list">
                                        <router-link :to="{name : 'payers'}">
                                            <span class="nav-text">Funders</span>
                                        </router-link>
                                    </li>
                                    <li v-if="payer_plan_list">
                                        <router-link :to="{name : 'payer-plans'}">
                                            <span class="nav-text">Funders Plans</span>
                                        </router-link>
                                    </li>
                                    <li v-if="agency_list">
                                        <router-link :to="{name : 'agency'}">
                                            <span class="nav-text">Agency</span>
                                        </router-link>
                                    </li>
                                    <li v-if="certification_list">
                                        <router-link :to="{name : 'certifications'}">
                                            <span class="nav-text">Certifications</span>
                                        </router-link>
                                    </li>
                                    <li v-if="location_list">
                                        <router-link :to="{name : 'locations'}">
                                            <span class="nav-text">Locations</span>
                                        </router-link>
                                    </li>
                                    <li v-if="service_line_list">
                                        <router-link :to="{name : 'service-line'}">
                                            <span class="nav-text">Service Line</span>
                                        </router-link>
                                    </li>
                                    <li v-if="service_list">
                                        <router-link :to="{name : 'service'}">
                                            <span class="nav-text">Services</span>
                                        </router-link>
                                    </li>
                                <!-- <ul class="nav-sub" v-if="hcodes_list">
                                    <li>
                                        <router-link :to="{name : 'billingcodes'}">
                                            <span class="nav-text">Billingcodes</span>
                                        </router-link>
                                    </li>
                                </ul> 
                                <ul class="nav-sub" v-if="diagnosis_list">
                                    <li>
                                        <router-link :to="{name : 'diagnosis'}">
                                            <span class="nav-text">Diagnosis</span>
                                        </router-link>
                                    </li>
                                </ul> -->
                                    <li v-if="program_list">
                                        <router-link :to="{name : 'program-target-type-list'}">
                                            <span class="nav-text">Program Target Types</span>
                                        </router-link>
                                    </li>
                                    <li v-if="goals_list">
                                        <router-link :to="{name : 'goal'}">
                                            <span class="nav-text">Target PlayList </span>
                                        </router-link>
                                    </li>
                                    <li v-if="hcodes_list">
                                        <router-link :to="{name : 'skillareas'}">
                                            <span class="nav-text">Skill Area</span>
                                        </router-link>
                                    </li>
                                    <li v-if="hcodes_list">
                                        <router-link :to="{name : 'bcbatasks'}">
                                            <span class="nav-text">BCBA Task</span>
                                        </router-link>
                                    </li>
                                <!-- <ul class="nav-sub" v-if="hcodes_list">
                                    <li>
                                        <router-link :to="{name : 'session-template'}">
                                            <span class="nav-text">Session Template</span>
                                        </router-link>
                                    </li>
                                </ul> -->
                                    <li v-if="hcodes_list">
                                        <router-link :to="{name : 'dynamic-session-template'}">
                                            <span class="nav-text">Dynamic Session Template</span>
                                        </router-link>
                                    </li>

                                <!-- <ul class="nav-sub" v-if="program_list">
                                    <li>
                                        <router-link :to="{name : 'programs'}">
                                            <span class="nav-text">Program Library</span>
                                        </router-link>
                                    </li>
                                </ul> -->
                                <!-- <ul class="nav-sub hide" v-if="assessments_category_list">
                                    <li>
                                        <router-link :to="{name : 'assessment-category'}">
                                            <span class="nav-text">Assessment Category</span>
                                        </router-link>
                                    </li>
                                </ul> -->
                                    <li v-if="stage_status_master_list">
                                        <router-link :to="{name : 'stage-status-master'}">
                                            <span class="nav-text">Stage Status Master</span>
                                        </router-link>
                                    </li>

                                    <li v-if="stage_status_list">
                                        <router-link :to="{name : 'stage-status'}">
                                            <span class="nav-text">Stage Status</span>
                                        </router-link>
                                    </li>
                                    <li v-if="behavior_category_list">
                                        <router-link :to="{name : 'behavior-category'}">
                                            <span class="nav-text">Behavior Category</span>
                                        </router-link>
                                    </li>
                                    <li  v-if="behavior_category_list">
                                        <router-link :to="{name : 'sessionform-category'}">
                                            <span class="nav-text">Note Helper Settings</span>
                                        </router-link>
                                    </li>
                                    <li v-if="target_accuracy_list">
                                        <router-link :to="{name : 'target-accuracy'}">
                                            <span class="nav-text">Target Accuracy</span>
                                        </router-link>
                                    </li>
                                    <li v-if="target_promo_code">
                                        <router-link :to="{name : 'target-promo-code'}">
                                            <span class="nav-text">Target Promo Code</span>
                                        </router-link>
                                    </li>
                                    <li v-if="agency_list">
                                        <router-link :to="{name : 'school'}">
                                            <span class="nav-text">School</span>
                                        </router-link>
                                    </li>
                                    <li v-if="service_list">
                                        <router-link :to="{name : 'coverage-types'}">
                                            <span class="nav-text">Coverage Types</span>
                                        </router-link>
                                    </li>
                                    <li v-if="certification_list">
                                        <router-link :to="{name : 'reinforcement'}">
                                            <span class="nav-text">Reinforcement</span>
                                        </router-link>
                                    </li>
                                    <li v-if="certification_list">
                                        <router-link :to="{name : 'group-template-lession'}">
                                            <span class="nav-text">Group Template Lesson</span>
                                        </router-link>
                                    </li>
                                    <li v-if="certification_list">
                                        <router-link :to="{name : 'prompt-code'}">
                                            <span class="nav-text">Prompt Codes</span>
                                        </router-link>
                                    </li>
                                </ul>
                          </li>
                          <!-- <li>
                            <a>
                                <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                <span class="nav-icon"><i class="fa fa-align-left"></i></span>
                                <span class="nav-text">Dynamic Forms</span>
                            </a>
                            <ul class="nav-sub" v-if="dynamic_field_list">
                                <li>
                                    <router-link :to="{name : 'dynamic-field'}">
                                        <span class="nav-text">Questions</span>
                                    </router-link>
                                </li>
                            </ul>
                            <ul class="nav-sub" v-if="assessments_list">
                                <li>
                                    <router-link :to="{name : 'assessment'}">
                                        <span class="nav-text">Assessment Forms</span>
                                    </router-link>
                                </li>
                            </ul>
                            <ul class="nav-sub" v-if="checklist_master_list">
                                <li>
                                    <router-link :to="{name : 'checklist-master'}">
                                        <span class="nav-text">Checklist Forms</span>
                                    </router-link>
                                </li>
                            </ul>
                            
                          </li> -->
                          <li v-if="file_list">
                              <router-link :to="{name : 'files'}">
                                  <span class="nav-icon"><i class="fa fa-file"></i></span>
                              </router-link>
                          </li>
                          <li v-if="folder_list">
                              <router-link :to="{name : 'folders'}">
                                  <span class="nav-icon"><i class="fa fa-folder"></i></span>
                              </router-link>
                          </li>
                          <li v-if="file_management">
                              <router-link :to="{name : 'file-management'}">
                                  <span class="nav-icon"><i class="fa fa-folder"></i></span>
                              </router-link>
                          </li>
                          <li>
                              <router-link :to="{name : 'intake-form'}">
                                  <span class="nav-icon"><i class="fa fa-file-text-o" aria-hidden="true"></i></span>
                              </router-link>
                          </li>
                          <!-- <li>
                              <router-link :to="{name : 'templates'}">
                                  <span class="nav-icon"><i class="fa fa-table" aria-hidden="true"></i></span>
                              </router-link>
                          </li> -->
                          <li>
                            <a>
                                <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                <span class="nav-icon"><i class="fa fa-table" aria-hidden="true"></i></span>
                            </a>
                            <ul class="nav-sub nav-sub-mobile">
                                <li>
                                    <router-link :to="{name : 'templates'}">
                                        <!-- <i class="fa fa-inbox d-inline p-2" aria-hidden="true"></i> -->
                                        <span class="nav-text d-inline">Template List</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{name : 'merge-field'}">
                                        <span class="nav-text d-inline">Merge Field</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{name : 'case-documnet-list'}">
                                        <span class="nav-text d-inline">Case Documents</span>
                                    </router-link>
                                </li>
                            </ul>
                            
                          </li>
                          <li>
                            <a>
                                <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                <span class="nav-icon"><i class="fa fa-bullseye" aria-hidden="true"></i></span>
                            </a>
                            <ul class="nav-sub nav-sub-mobile">
                                <li>
                                    <router-link :to="{name : 'excel-document'}">
                                        <!-- <i class="fa fa-plus d-inline p-2" aria-hidden="true"></i> -->
                                        <span class="nav-text d-inline">Excel to Document</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{name : 'document-excel'}">
                                        <!-- <i class="fa fa-inbox d-inline p-2" aria-hidden="true"></i> -->
                                        <span class="nav-text d-inline">Document to Excel</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{name : 'goal-activity-list'}">
                                        <!-- <i class="fa fa-inbox d-inline p-2" aria-hidden="true"></i> -->
                                        <span class="nav-text d-inline">Goal Activity</span>
                                    </router-link>
                                </li>
                            </ul>
                            
                          </li>
                          <li>
                            <a>
                                <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                <span class="nav-icon"><i class="fa fa-envelope"></i></span>
                            </a>
                            <ul class="nav-sub nav-sub-mobile">
                                <li>
                                    <router-link :to="{name : 'compose'}">
                                        <!-- <i class="fa fa-plus d-inline p-2" aria-hidden="true"></i> -->
                                        <span class="nav-text d-inline">Compose</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{name : 'inbox'}">
                                        <!-- <i class="fa fa-inbox d-inline p-2" aria-hidden="true"></i> -->
                                        <span class="nav-text d-inline">Inbox</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{name : 'send'}">
                                        <!-- <i class="fa fa-paper-plane d-inline p-2" aria-hidden="true"></i> -->
                                        <span class="nav-text d-inline">Sent</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{name : 'trash'}">
                                        <!-- <i class="fa fa-trash d-inline p-2" aria-hidden="true"></i> -->
                                        <span class="nav-text d-inline">Trash</span>
                                    </router-link>
                                </li>
                            </ul>
                            
                          </li>
                          <li>
                            <router-link :to="{name : 'reminders'}">
                                <span class="nav-icon"><i class="fa fa-bell"></i></span>
                            </router-link>
                          </li>
                          <li v-if="role_list">
                            <a>
                                <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                <span class="nav-icon"><i class="fa fa-user-circle-o"></i></span>
                            </a>
                            <ul class="nav-sub nav-sub-mobile">
                                <li>
                                    <router-link :to="{name : 'roles'}">
                                        <span class="nav-text">Role Group</span>
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{name : 'user-group'}">
                                        <span class="nav-text">Add User Group</span>
                                    </router-link>
                                </li>
                            </ul>
                          </li>
                          <li v-if="office_staff_list">
                              <router-link :to="{name : 'parents'}">
                                  <span class="nav-icon"><i class="fa fa-male"></i></span>
                              </router-link>
                          </li>
                          <li v-if="office_staff_list">
                              <a>
                                  <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                  <span class="nav-icon"><i class="fa fa-users"></i></span>
                              </a>
                              <ul class="nav-sub nav-sub-mobile">
                                <li>
                                    <router-link :to="{name : 'users'}">
                                        <span class="nav-text">Staff List</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{name : 'staff-salary'}">
                                        <span class="nav-text">Staff Salary</span>
                                    </router-link>
                                </li>
                                <!-- <li>
                                    <router-link :to="{name : 'daily-salary'}">
                                        <span class="nav-text">Daily Salary</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{name : 'due-payout'}">
                                        <span class="nav-text">Due Payout</span>
                                    </router-link>
                                </li> -->
                            </ul>
                          </li>
                          <li v-if="student_list">
                            <router-link :to="{name : 'student'}">
                                <span class="nav-icon"><i class="fa fa-child"></i></span>
                            </router-link>
                          </li>
                          <li v-if="invoice_list">
                            <router-link :to="{name : 'invoice-list'}">
                                <span class="nav-icon"><i class="fa fa-clipboard"></i></span>
                            </router-link>
                          </li>
                          <!-- <li>
                            <router-link :to="{name : 'graph'}">
                                <span class="nav-icon"><i class="fa fa-area-chart"></i></span>
                            </router-link>
                          </li> -->
                          <li v-if="office_staff_list">
                              <a>
                                  <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                  <span class="nav-icon"><i class="fa fa-area-chart"></i></span>
                              </a>
                              <ul class="nav-sub nav-sub-mobile">
                                    <!-- <li>
                                        <router-link :to="{name : 'graph-abc-behavior'}">
                                            <span class="nav-text">Abc Graph</span>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{name : 'graph-student-behavior'}">
                                            <span class="nav-text">Students Behavior Graph</span>
                                        </router-link>
                                    </li> -->
                                    <li>
                                        <router-link :to="{name : 'graph-student-skill-behav'}">
                                            <span class="nav-text">Students Behavior Graph</span>
                                        </router-link>
                                    </li>
                                </ul>
                          </li>
                          
                          <!-- <li>
                            <a>
                                <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                <span class="nav-icon"><i class="fa fa-align-left"></i></span>
                                <span class="nav-text">Session</span>
                            </a>
                            <ul class="nav-sub" v-if="session_template_type">
                                <li>
                                    <router-link :to="{name : 'session-type'}">
                                        <span class="nav-text">Session Type</span>
                                    </router-link>
                                </li>
                            </ul>
                            <ul class="nav-sub" v-if="session_template_master">
                                <li>
                                    <router-link :to="{name : 'session-master'}">
                                        <span class="nav-text">Session Templates</span>
                                    </router-link>
                                </li>
                            </ul>
                            <ul class="nav-sub" v-if="session_template_form">
                                <li>
                                    <router-link :to="{name : 'session-form'}">
                                        <span class="nav-text">Session Form</span>
                                    </router-link>
                                </li>
                            </ul>
                            
                          </li> -->

                          

                          <!-- <li>
                            <router-link :to="{name : 'intake-form'}">
                                <span class="nav-caret"><i class="fa fa-caret-down"></i></span>
                                <span class="nav-icon"><i class="fa fa-file-text-o" aria-hidden="true"></i></span>
                                <span class="nav-text">Intake Form</span>
                            </router-link>
                            <ul class="nav-sub">
                                <li>
                                    <router-link :to="{name : 'intake-form'}">
                                        <i class="fa fa-align-left d-inline p-2" aria-hidden="true"></i>
                                        <span class="nav-text d-inline">Intake Forms</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{name : 'add-intake-form'}">
                                        <i class="fa fa-plus d-inline p-2" aria-hidden="true"></i>
                                        <span class="nav-text d-inline">Add Intake Form</span>
                                    </router-link>
                                </li>
                            </ul>
                            
                          </li> -->
                          
                          <!-- <li>
                            <router-link :to="{name : 'invoice'}">
                                <span class="nav-icon"><i class="fa fa-files-o"></i></span>
                                <span class="nav-text">Billing</span>
                            </router-link>
                          </li> -->
                          <li class="pb-2 hidden-folded"></li>
                      </ul>
                  </div>
              </div>
            </div>
            <!-- sidenav bottom -->
            <!-- <div class="no-shrink lt">
              <div class="nav-fold">
                  <a class="d-flex p-2-3" data-toggle="dropdown">
                      <span class="avatar w-28 grey hide">J</span>
                      <img src="@/assets/images/a0.jpg" alt="..." class="w-28 circle">
                  </a>
                  <div class="dropdown-menu  w pt-0 mt-2 animate fadeIn">
                      <a class="dropdown-item" @click="logOut">Sign out</a>
                  </div>
                  <div class="hidden-folded flex p-2-3 bg">
                      <div class="d-flex p-1">
                          <a href="#" class="flex text-nowrap">
                              <i class="fa fa-bell text-muted mr-1"></i>
                              <span class="badge badge-pill theme">20</span>
                          </a>
                          <a class="px-2" title="Logout" @click="logOut">
                              <i class="fa fa-power-off text-muted"></i>
                          </a>
                      </div>
                  </div>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
    import { mapActions } from 'vuex'
    export default {
        name: "ProfileSidebarMobile",
        components: {
        },
        props: {
            show_div_type: {
                type: Boolean,
                default: false,
            },
        },
        data () {
            return {
                user_type: '',
                isSuperAdmin: false,
                package_list:false,
                agency_list:false,
                school_list: false,
                service_list:false,
                service_line_list:false,
                payers_list:false,
                hcodes_list:false,
                goals_list:false,
                diagnosis_list:false,
                program_list:false,
                folder_list:false,
                file_management: false,
                assessments_category_list: false,
                file_list:false,
                assessments_list:false,
                certification_list:false,
                location_list: false,
                stage_status_master_list:false,
                checklist_master_list:false,
                dynamic_field_list:false,
                role_list:false,
                checklist_form_template_list:false,
                stage_status_list:false,
                payer_plan_list:false,
                session_template_type:false,
                session_template_master:false,
                session_template_form:false,
                behavior_category_list:false,
                office_staff_list:false,
                student_list:false,
                invoice_list:false
            }
        },
        created() {
            this.package_list = this.userPermission('package-list');
            this.agency_list = this.userPermission('agency-list');
            this.service_list = this.userPermission('service-list');
            this.service_line_list = this.userPermission('service-line-list');
            this.payers_list = this.userPermission('payers-list');
            this.hcodes_list = this.userPermission('hcodes-list');
            this.goals_list  = this.userPermission('goals-list');
            this.diagnosis_list = this.userPermission('diagnosis-list');
            this.program_list = this.userPermission('program-list');
            this.folder_list = this.userPermission('folder-list');
            this.file_management = this.userPermission('folder-list');
            this.assessments_category_list = this.userPermission('assessments-category-list');
            this.file_list = this.userPermission('file-list');
            this.assessments_list = this.userPermission('assessments-list');
            this.certification_list = this.userPermission('certification-list');
            this.stage_status_master_list = this.userPermission('stage-status-master-list');
            this.checklist_master_list = this.userPermission('checklist-masters-list');
            this.dynamic_field_list = this.userPermission('dynamic-fields-list');
            this.role_list = this.userPermission('role-list');
            this.checklist_form_template_list = this.userPermission('checklist-form-template-list');
            this.stage_status_list = this.userPermission('stage-status-list')
            this.payer_plan_list = this.userPermission('payers-plan-list');
            this.session_template_type = this.userPermission('session-template-type-list');
            this.session_template_master = this.userPermission('session-template-master-list');
            this.session_template_form = this.userPermission('session-form-template-list');
            this.behavior_category_list = this.userPermission('behavior-list');
            this.location_list = this.userPermission('location-list');
            this.office_staff_list = this.userPermission('office-staff-list');
            this.student_list = this.userPermission('student-list');
            this.target_accuracy_list = this.userPermission('hcodes-list');
            this.target_promo_code = this.userPermission('hcodes-list');
            this.school_list = this.userPermission('school-list');
            this.invoice_list = this.userPermission('student-list');
        },

        computed: {

        },

        mounted() {
            this.checkUserType();
        },


        methods: {
            ...mapActions(['logout']),
            logOut(){
                this.ishover = 0;
                this.ishover = 0;
                this.$store.commit('logout');
                this.$router.push({name : 'home'});
            },
            userPermission(permission_name){
              let userPermission = JSON.parse(localStorage.getItem('userPermission'));
              for (var i=0; i < userPermission.length; i++) {
                if (userPermission[i].name == permission_name) {
                  return true;
                }
              }
              return false;
            },
            checkUserType() {
                let userType = JSON.parse(localStorage.getItem('userType'));
                this.user_type = userType;
                if (userType == 'Super Admin') {
                    this.isSuperAdmin = true;
                }
            }

        },
    }
</script>
