<template>
    <header class="o-header " v-bind:class= "[header_type]">
        <!-- Header -->
        <div class="content-header white  box-shadow-0" id="content-header">
            <div class="navbar navbar-expand-lg">
                <!-- btn to toggle sidenav on small screen -->
                <a  class="d-lg-none mx-2 mobile-toggle-btn" data-toggle="modal" data-target="#aside" @click="mobileToggleSideProfile">
                    <img src="@/assets/images/toggle-icon.png" alt="toggle">
                </a>
                <!-- Page title -->
                <div class="navbar-text nav-title flex toggle-btn" id="pageTitle">
                  <a href="#" @click="toggleSideProfile"><img src="@/assets/images/toggle-icon.png" alt="toggle"></a>
                </div>
                <div v-if="hrPlatformUrl && checkingDifferentLableMethod('Go To Website')" class="navbar-text nav-title flex-1 mx-2">
                    <a style="font-size:14px" :href="hrPlatformUrl" target="_blank">
                        HR Platform
                        <i class="fa fa-external-link"></i>
                    </a>
                </div>
                <div class="navbar-text nav-title flex-1 pr-2" id="welcomeMsg">Hello {{loggedInUserName}}</div>
                <ul class="nav flex-row order-lg-2 user-profile">
                    <!-- Notification -->
                    <!-- <li class="nav-item dropdown">
                        <div class="dropdown-menu dropdown-menu-right w-md animate fadeIn mt-2 p-0">
                            <div class="scrollable hover" style="max-height: 250px">
                                <div class="list">
                                    <div class="list-item " data-id="item-7">
                                        <span class="w-24 avatar circle indigo">
                                        <span class="fa fa-bug"></span>
                                        </span>
                                        <div class="list-body">
                                            <a href="" class="item-title _500">Fifth Harmony</a>
                                            <div class="item-except text-sm text-muted h-1x">
                                                Send you a invitation to SWO
                                            </div>
                                            <div class="item-tag tag hide">
                                            </div>
                                        </div>
                                        <div>
                                            <span class="item-date text-xs text-muted">05:35</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex px-3 py-2 b-t">
                                <div class="flex">
                                    <span>1 Notification</span>
                                </div>
                                <a href="#">See all
                                    <i class="fa fa-angle-right text-muted"></i>
                                </a>
                            </div>
                        </div>
                    </li> -->
                    <!-- User dropdown menu -->
                    <li class="dropdown d-flex align-items-center">
                        <a href="#" data-toggle="dropdown" class="d-flex align-items-center">
                            <span class="avatar w-32"> 
                                <img v-if="profile_url&&profile_url!=''" :src="profile_url" alt="...">
                                <img v-if="!profile_url||profile_url==''" src="@/assets/images/a3.jpg" alt="...">
                            </span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right w pt-0 mt-2 animate fadeIn">
                            <div class="row no-gutters b-b mb-2">

                            </div>                              
                            <router-link :to="{name : 'profile'}">
                                <a class="dropdown-item" >Profile</a>
                            </router-link>
                            <a class="dropdown-item" @click="logOut">Sign out</a>
                        </div>
                    </li>
                    <!-- Navarbar toggle btn -->
                    <!-- <li class="d-lg-none d-flex align-items-center">
                        <a href="#" class="mx-2" data-toggle="collapse" data-target="#navbarToggler">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 512 512">
                                <path d="M64 144h384v32H64zM64 240h384v32H64zM64 336h384v32H64z" />
                            </svg>
                        </a>
                    </li> -->
                </ul>
                <!-- Navbar collapse -->
            </div>
        </div>
        <div class="page-header">
            <div class="col-sm-12 col-md-12">
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <h1 class="pl-0 pl-lg-2 pl-md-2 pl-sm-0" v-if="selectedPayerName!=''&&headerTitle!=''&&isPayerRoute">{{ headerTitle + ' - ' + selectedPayerName }}</h1>
                        <!-- <h1 class="pl-0 pl-lg-2 pl-md-2 pl-sm-0" v-else>{{ headerTitle }}</h1> -->
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <Breadcrumbs class="d-flex justify-content-md-end flex-wrap"/>
                        <!-- <v-breadcrumbs
                            :items="breadCrumbs"
                            divider=">"
                        >
                            <template v-slot:item="props">
                            <router-link :to="props.item.href" v-if="!props.item.disabled">
                                <v-breadcrumbs-item
                                :class="[props.item.disabled && 'disabled']"
                                >
                                {{ props.item.text }}
                                </v-breadcrumbs-item>
                            </router-link>
                            <v-breadcrumbs-item
                                :class="[props.item.disabled && 'disabled']"
                                v-else
                            >
                                {{ props.item.text }}
                            </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <div id="customDialog" class="modal"> -->
        <div id="customDialog" name="error_notify" transition="scale" class="modal content-main col-md-12 modal-respon" height="auto">
    
            <div class="modal-content custom-modal-content">            
                <span class="close-button" id="closeModal">×</span>
                <p id="modal-message">Some text in the Modal..</p>
            </div>
        </div>
        <!-- </div> -->

        <modal name="document_notify" transition="scale" class="content-main col-md-12 modal-respon" height="auto">
            <div class="main-wrape">
                <div class="box-header">
                    <button type="button" class="close" @click="hide('document_notify')">
                        <span>×</span>
                    </button>
                    <h2>Please Upload Documents</h2>
                </div>
                <div class="box-divider m-0"></div>
                <div class="box-body">
                    {{ notify_message.split(':')[0]+':' }} <br />
                    <strong>{{ notify_message.split(':')[1] }}</strong>
                </div>    

                <div class="col-sm-12 px-0 mt-2 mb-2 ml-2">
                    <button type="button" @click="gotoStaffDocumentUpload" class="btn btn-green primary">Upload Now</button>&nbsp;
                </div>
            </div> 
        </modal>

    </header>
</template>
<script>

    import { createNamespacedHelpers, mapGetters } from 'vuex'
    const { mapActions } = createNamespacedHelpers('auth')
    import '../../../assets/js/nav.js'
    import '../../../assets/js/scrollto.js'
    import '../../../assets/js/toggleclass.js'
    import Api from '../../../service/api';

    export default {
        name: "MainHeader",
        data() {
            return {
                user_id: '',
                payerRoutes:[],
                headerTitle: '',
                isPayerRoute: false,
                profile_url: '',
                loggedInUserType: '',
                notify_message: '',
                hrPlatformUrl: null,
                currentPremittedMethods: [],

            };
        },
        components: {},
        created() {
            this.getUserDetails();
        },
        computed: {
            ...mapGetters({
                isExpendedSideBar: "isExpendedSideBar",
                loggedInUserName: "loggedInUserName",
                loggedInUserEmail: "loggedInUserEmail",
                selectedPayerName: "selectedPayerName",
                isSuperAdmin: "isSuperAdmin",
            }),
            ...mapGetters('sample_files', [
                'getAllSampleFiles'
            ]),
            currentRouteName() {
                return this.$route.name;
            },
            currentTitle() {
                return this.headerTitle;
            }
        },

        mounted() {
            // this.headerTitle = this.$route.meta.breadcrumb ? (this.$route.meta.breadcrumb.label ? this.$route.meta.breadcrumb.label : this.$route.meta.breadcrumb) : '';
            this.currentPageHeaderName();

            // get loggedInUserEmail from computed property
            const loggedInUserEmail = this.loggedInUserEmail;
            
            const inputString = loggedInUserEmail;

            // secret key for encryption (keep this secure)
            const secretKey = process.env.VUE_APP_ENCRYPTION_KEY;

            // Convert the secret key to an array of ASCII values
            const keyArray = Array.from(secretKey, char => char.charCodeAt(0));

            // Convert the input string to an array of ASCII values
            const inputArray = Array.from(inputString, char => char.charCodeAt(0));

            // Perform XOR operation
            const encryptedArray = inputArray.map((charCode, index) => charCode ^ keyArray[index % keyArray.length]);

            // Convert the encrypted array back to a string
            const encryptedString = String.fromCharCode(...encryptedArray);

            const hrPlatformUrl = process.env.VUE_APP_HR_SITE_URL;

            this.hrPlatformUrl = hrPlatformUrl + 'login?token=' + btoa(encryptedString);
            
            let userPermission = JSON.parse(localStorage.getItem('userPermission'));
            this.currentPremittedMethods = _.filter(userPermission, ['subLabel', 'HR Platform']);

            if(this.getAllSampleFiles.length<1) {
                this.$store.dispatch('sample_files/fetchSampleFiles');
            }
        },
        // beforeDestroy(){
        //     console.log("Welcome to before destroy");
        //     this.$store.dispatch("setSelectedPayerName", '');
        // },
        methods: {
            ...mapActions(['logout']),
            // ...mapActions('sample_files', [
            //     'fetchSampleFiles',
            //     'fetchSampleFileTypes'
            // ]),
            async logOut(){
                this.ishover = 0;
                await Api.logout();
                this.$store.commit('logout');
                this.$router.push({name : 'home'});
            },

            checkingDifferentLableMethod(method_name) {
                if(this.isSuperAdmin) {
                    return true;
                } else {
                    let find_value = _.find(this.currentPremittedMethods, ['method', method_name]);
                    if(find_value) {
                        return true;
                    } else {
                        return false;
                    }
                }
            },

            toggleSideProfile() {
                this.$store.dispatch("setSideBarExpended", !this.isExpendedSideBar);
            },

            mobileToggleSideProfile() {
                this.$store.dispatch("setSideBarExpended", true);
            },
            async getUserDetails() {
                let response = {};
                try {
                    response = await Api.getAuth();
                    if(response.status === 200 && response.data.data){
                        this.user_id = response.data.data.id;
                        var user_id = response.data.data.id;
                        this.loggedInUserType = response.data.data.user_type?response.data.data.user_type:'';
                        if(this.loggedInUserType!=''&&this.loggedInUserType!='Super Admin'){
                            await this.getStaffDocumentNotification();
                        }
                        let userMeta = response.data.data.user_meta;
                        if (userMeta.length > 0) {
                            for (let index = 0; index < userMeta.length; index++) {
                                if(userMeta[index].metaKey == 'profile_image') {
                                    if(userMeta[index].metaValue&&userMeta[index].metaValue!='') {
                                        this.profile_url = response.data.data.imagebasepath+'/'+userMeta[index].metaValue;
                                    }else {
                                        this.profile_url = ''
                                    }
                                }
                            }
                        }
                    }
                } catch(error){
                    // console.log('error.response.data', error.response.data);
                    if(error.response.data.status == '302') {
                        this.$router.push({name : 'home'});
                        // this.logOut();
                        this.$store.commit('logout');
                    }
                }          
            },
            async getStaffDocumentNotification() {
                let response = {};
                try {
                    response = await Api.getStaffDocumentNotification();
                    // console.log(response);
                    if(response.status === 200){
                        this.notify_message = response.data.message;
                        if(response.data.pending_file_count){
                            this.$modal.show('document_notify');
                        }
                    }
                } catch(error){
                    if(error.response.data.status == '302') {
                        this.$router.push({name : 'home'});
                        this.$store.commit('logout');
                    }
                }
            },
            gotoStaffDocumentUpload() {
                this.$modal.hide('document_notify');
                // console.log("Route to Staff Document upload!");
                this.$router.push({
                    name: 'profile-documents',
                    params: {
                        user_id: this.user_id
                    }
                })
            },
            hide(name) {
                this.$modal.hide(name);
            },
            currentPageHeaderName(route=null) {
                let rou = route ? route : this.$route.name;
                this.headerTitle = this.$route.meta.breadcrumb ? (this.$route.meta.breadcrumb.label ? this.$route.meta.breadcrumb.label : this.$route.meta.breadcrumb) : '';
                if(rou=='edit-payer'||rou=='payer-billing-code'||rou=='add-payer-billing-code'||rou=='payer-prompt-code'||rou=='payer-cases'||rou=='add-payer-case'||rou=='edit-payer-case') {
                    this.isPayerRoute = true;
                    this.headerTitle = this.$route.meta.breadcrumb ? (this.$route.meta.breadcrumb.label ? this.$route.meta.breadcrumb.label : this.$route.meta.breadcrumb) : '';
                }else {
                    this.isPayerRoute = false;
                    this.headerTitle = this.$route.meta.breadcrumb ? (this.$route.meta.breadcrumb.label ? this.$route.meta.breadcrumb.label : this.$route.meta.breadcrumb) : '';
                    this.$store.dispatch("setSelectedPayerName", '');
                }
                // return headerTitle;
            },
        },
        watch: {
            '$route': {
                handler: function(value) {
                    // console.log(value.name);
                    this.currentPageHeaderName(value.name);
                },
                deep: true
            }
        },       
        props: {
            header_type: {
                type: String,
            }
        }
    }
</script>

<style>

 .modal {
    display: none;
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    padding-top: 60px; 
}

.custom-modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
}

.close-button {
    color: #aaa;
    /* float: right; */
    font-size: 20px;
    font-weight: bold;
    text-align:right;
    
} 

.close-button:hover,
.close-button:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
#modal-message{
    font-size: 18px;
    font-weight: bold;
    color: #b50303;
    text-align-last: center;
}
</style>
