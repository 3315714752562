import * as moment from "moment";

export const formatDateTime = (dateTimeValue) => {
    var dateTime = moment(dateTimeValue).format("DD-MM-YYYY h:mm:ss");
    return dateTime;
};

export const formatUSDateTime = (dateTimeValue) => {
  // console.log(dateTimeValue);
  var dateTime = moment(dateTimeValue).format("MM/DD/YYYY HH:mm:ss");
  return dateTime;
};

export const downloadFile = async function (filePath, fileName) {
  const a = document.createElement("a");
  a.href = filePath;
  a.download = fileName;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const getPermissionListByLabel = function(data) {
  // console.log(data);
  if (!data||!data.length) return null
  // Create an object to store the grouped data
  const groupedData = {};

  // Loop through the input and group by instruction_name
  data.forEach((item) => {
    // const { year } = item;
    if (!groupedData[item.label]) {
      groupedData[item.label] = [];
    }
    groupedData[item.label].push(item);
  });

  return groupedData;
}

export const getPermissionListByRoleLabel = function(data) {
  // console.log(data);
  if (!data||!data.length) return null
  // Create an object to store the grouped data
  const groupedData = {};

  // Loop through the input and group by instruction_name
  data.forEach((item) => {
    // const { year } = item;
    if (!groupedData[item.subLabel]) {
      groupedData[item.subLabel] = [];
    }
    groupedData[item.subLabel].push(item);
  });

  return groupedData;
}

export const checkPermissionSubLabel = function(data) {
  // console.log(data);
  if (!data||!data.length) return null
  // Create an object to store the grouped data
  let isSublabel = false;

  if (data[0].subLabel != null) {
    isSublabel = true;
  }

  return isSublabel;
}