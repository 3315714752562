<template>
    <div>
        <div class="d-flex flex-column flex">
            <header-layout :header_type = "header"></header-layout>
         
            <router-view :key="$route.fullPath"></router-view>
        </div>
    </div>
</template>
<script>

import HeaderLayout from "../components/public/layout/Header";

export default {
    name: 'Public',
    components: {
        HeaderLayout
    },
    data() {
        return {
            center: { lat: null, lng: null },
        }
    },
    props: {
        header: {
            type: String
        }
    },
    mounted() {
        this.geolocate();
    },
    watch: {
        center: {
            handler: function (val, oldVal) {
                this.$store.dispatch("setLocationIdentifier", this.center);
            },
            deep: true
        },
    },
    methods: {
        geolocate: function() {
            navigator.geolocation.getCurrentPosition(position => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
            });

            this.$store.dispatch("setLocationIdentifier", this.center);
        },
    },
}
</script>