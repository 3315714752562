// Florala Editor
// js
require('froala-editor/js/froala_editor.pkgd.min.js');
require('froala-editor/js/froala_editor.min.js');
require('froala-editor/js/plugins/align.min.js');
require('froala-editor/js/plugins/char_counter.min.js');
require('froala-editor/js/plugins/code_beautifier.min.js');
require('froala-editor/js/plugins/code_view.min.js');
require('froala-editor/js/plugins/colors.min.js');
require('froala-editor/js/plugins/draggable.min.js');
require('froala-editor/js/plugins/emoticons.min.js');
require('froala-editor/js/plugins/entities.min.js');
require('froala-editor/js/plugins/file.min.js');
require('froala-editor/js/plugins/font_size.min.js');
require('froala-editor/js/plugins/font_family.min.js');
require('froala-editor/js/plugins/fullscreen.min.js');
require('froala-editor/js/plugins/image.min.js');
require('froala-editor/js/plugins/image_manager.min.js');
require('froala-editor/js/plugins/line_breaker.min.js');
require('froala-editor/js/plugins/inline_style.min.js');
require('froala-editor/js/plugins/link.min.js');
require('froala-editor/js/plugins/lists.min.js');
require('froala-editor/js/plugins/paragraph_format.min.js');
require('froala-editor/js/plugins/paragraph_style.min.js');
require('froala-editor/js/plugins/quick_insert.min.js');
require('froala-editor/js/plugins/quote.min.js');
require('froala-editor/js/plugins/table.min.js');
require('froala-editor/js/plugins/save.min.js');
require('froala-editor/js/plugins/url.min.js');
require('froala-editor/js/plugins/video.min.js');
require('froala-editor/js/plugins/help.min.js');
require('froala-editor/js/plugins/print.min.js');
require('froala-editor/js/third_party/spell_checker.min.js');
require('froala-editor/js/plugins/special_characters.min.js');
require('froala-editor/js/plugins/word_paste.min.js');
// css
require('froala-editor/css/froala_editor.pkgd.min.css');
require('froala-editor/css/froala_style.min.css');
require('froala-editor/css/plugins/code_view.css');
require('froala-editor/css/plugins/draggable.css');
require('froala-editor/css/plugins/colors.css');
require('froala-editor/css/plugins/emoticons.css');
require('froala-editor/css/plugins/image_manager.css');
require('froala-editor/css/plugins/image.css');
require('froala-editor/css/plugins/line_breaker.css');
require('froala-editor/css/plugins/table.css');
require('froala-editor/css/plugins/char_counter.css');
require('froala-editor/css/plugins/video.css');
require('froala-editor/css/plugins/fullscreen.css');
require('froala-editor/css/plugins/file.css');
require('froala-editor/css/plugins/quick_insert.css');
require('froala-editor/css/plugins/help.css');
require('froala-editor/css/third_party/spell_checker.css');
require('froala-editor/css/plugins/special_characters.css');