const state = {
    helpDeskFormIsVisible: false,
    helpDeskFormCapturing: false,
    helpDeskFormEnableScreenshotEdit: false,
    helpDeskFormScreenshotEditorState: null,
    helpDeskFormScreenshot: null,
    helpDeskFormEditedScreenshot: null,
    helpDeskFormName: null,
    helpDeskFormEmail: null,
    helpDeskFormFeedback: null,
    helpDeskFormConsent: false,
    helpDeskFormNotification: null
};
const mutations = {
    setHelpDeskFormName(state, payload) {
        state.helpDeskFormName = payload;
    },
    setHelpDeskFormEmail(state, payload) {
        state.helpDeskFormEmail = payload;
    },
    setHelpDeskFormFeedback(state, payload) {
        state.helpDeskFormFeedback = payload;
    },
    setHelpDeskFormConsent(state, payload) {
        state.helpDeskFormConsent = payload;
    },
    setHelDeskFormCapturing(state, payload) {
        state.helpDeskFormCapturing = !!payload;
    },
    setHelpDeskFormScreenshotEditorState(state, payload) {
        state.helpDeskFormScreenshotEditorState = payload;
    },
    setHelpDeskFormEnableScreenshotEdit(state, payload) {
        state.helpDeskFormEnableScreenshotEdit = !!payload;
    },
    setHelpDeskFormNotification(state, payload) {
        state.helpDeskFormNotification = payload;
    },
    makeHelDeskFormVisible(state) { state.helpDeskFormIsVisible = true; },
    makeHelDeskFormHidden(state) { state.helpDeskFormIsVisible = false; },
    setHelpDeskFormScreenShot(state, payload) { state.helpDeskFormScreenshot = payload; },
    setHelpDeskFormEditedScreenShot(state, payload) { state.helpDeskFormEditedScreenshot = payload; }
};
const actions = {
    hideHelpDeskForm(context) {context.commit('makeHelDeskFormHidden');},
    showHelpDeskForm(context) {context.commit('makeHelDeskFormVisible');},
    resetHelpDeskForm(context) {
        context.commit('setHelpDeskFormName', null);
        context.commit('setHelpDeskFormEmail', null);
        context.commit('setHelpDeskFormFeedback', null);
        context.commit('setHelpDeskFormConsent', false);
        context.commit("setHelpDeskFormScreenShot", null);
        context.commit('setHelpDeskFormEditedScreenShot', null);
        context.commit('setHelpDeskFormScreenshotEditorState', null);
        context.commit('makeHelDeskFormHidden');
    },
    removeHelpDeskFormScreenshot(context) {
        context.commit('setHelpDeskFormScreenShot', null);
        context.commit('setHelpDeskFormEditedScreenShot', null);
        context.commit('setHelpDeskFormScreenshotEditorState', null);
    }
};
const getters = {
    outputScreenShot: (state) => {
        if (state.helpDeskFormEditedScreenshot) {
            return state.helpDeskFormEditedScreenshot;
        }
        return state.helpDeskFormScreenshot;
    },
    hasWarningForExit: (state) => {
        return !!state.helpDeskFormScreenshot ||
            !!state.helpDeskFormName ||
            !!state.helpDeskFormEmail ||
            !!state.helpDeskFormFeedback;
    }
};
export default {
    state,
    mutations,
    actions,
    getters
}
