import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import jQuery from 'jquery'
import popperJS from 'vue-popperjs'
import 'bootstrap/dist/js/bootstrap.min.js'
// import paceProgress from 'pace-progressbar'
// import 'pace-progressbar/themes/blue/pace-theme-barber-shop.css'
import App from './App.vue'
import PublicVue from './views/public.vue';
import SingleVue from './views/singl.vue';
import ProtectedVue from './views/protected.vue';
import router from './routers/router'
import store from './store'
import PortalVue from 'portal-vue'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
//import NProgress from 'vue-nprogress'
import _ from 'lodash';
import axios from 'axios';
//import $axios from './axiosInstance'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import VueCkeditor from 'vue-ckeditor5';
import Multiselect from 'vue-multiselect';
import InputTag from 'vue-input-tag';
import vueSignature from "vue-signature";
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import VModal from 'vue-js-modal'
import VueDragDrop from 'vue-drag-drop';
import "./vee-validate";
import "./filter";
import "./froala-editor";
import 'vue-simple-context-menu/dist/vue-simple-context-menu.css';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import VueBreadcrumbs from 'vue-2-breadcrumbs';
// import VueCompositionAPI from '@vue/composition-api'
import * as VueGoogleMaps from 'vue2-google-maps'

//VueCtkDateTimePicker
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
Vue.component('CtkDateTime', VueCtkDateTimePicker);

import '../node_modules/timeline-vuejs/dist/timeline-vuejs.css';


import VueFroala from 'vue-froala-wysiwyg'
Vue.use(VueFroala)

export const bus = new Vue();
window.Vue = Vue;
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.use({
  install: function(Vue, options){
      Vue.prototype.$jQuery = require('jquery');
  }
})
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_KEY,
    libraries: 'places',
  }
});
//Vue.use(DataTable)
Vue.use(popperJS)
// Vue.use(paceProgress)
Vue.use(_)
Vue.use(VueSweetalert2)
Vue.use(vueSignature)
Vue.component("VueTimepicker",VueTimepicker)
Vue.use(VModal)
Vue.use(VueDragDrop)
Vue.use(VueBreadcrumbs)
// Vue.use(VueCompositionAPI)
Vue.use(DatePicker);

const options = {
  editors: {
    classic: ClassicEditor,
  },
  name: 'ckeditor',
}
Vue.use(VueCkeditor.plugin, options);

import VueFlashMessage from 'vue-flash-message';

//Vue.use(jQuery)
Vue.config.productionTip = false
Vue.use(VueFlashMessage,{
  messageOptions: {
    timeout: 3000,
  }});
Vue.use(PortalVue)
Vue.component("PublicVue", PublicVue);
Vue.component("SingleVue", SingleVue);
Vue.component("ProtectedVue", ProtectedVue);
Vue.component('input-tag', InputTag)
// axios.interceptors.request.use(
//   paceProgress.start()
// )
Vue.component('multiselect', Multiselect)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
