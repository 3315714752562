import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from './store/modules/auth'
import helper from './store/modules/helper'
import help_desk from "./store/modules/help_desk";
import student_list from "./store/modules/student_list";
import sample_files from "./store/modules/sample_files";

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  modules: {
    auth: auth,
    helper:helper,
    help_desk,
    student_list,
    sample_files
  },
  plugins: [createPersistedState({
    paths: ['auth', 'helper']
  })],
})
