<script>
import {mapState} from "vuex";

export default {
  name: "HelpDeskNotification",
  computed: {
    ...mapState({
      notification: (state) => state.help_desk.helpDeskFormNotification
    })
  },
  watch: {
    notification(value) {
      value ? this.openModal(): this.onCloseModal();
    }
  },
  methods: {
    openModal() {
      this.$modal.show('help-desk-notification');
    },
    onCloseModal() {
      this.$modal.hide('help-desk-notification');
      this.$store.commit('setHelpDeskFormNotification', null);
    }
  },
  mounted() {
    if (this.notification) {
      this.openModal();
    }
  }
}
</script>

<template>
  <div class="app-helpdesk-notification-area">
    <modal name="help-desk-notification" :width="500" :scrollable="false" height="auto" class="modal-response content-main col-md-12" :clickToClose="false">
      <div class="box-header pb-0">
        <div class="d-flex flex-row align-items-center">
          <h2 class="mr-auto">Message</h2>
          <a @click.prevent="onCloseModal" class="ml-auto"> <i class="fa fa-window-close text-danger h4" aria-hidden="true"></i></a>
        </div>
      </div>
      <div class="box-body app-helpdesk-notify">{{notification}}</div>
    </modal>
  </div>
</template>

<style scoped>

</style>