// // GET hostname from Current url
// let BASE_URL ='';
// let SITE_URL = '';
// var parser = document.createElement('a');
// const CURRENT_URL = window.location.href;
// parser.href = CURRENT_URL;
// //let HOST_NAME = 'dev106.developer24x7.com';
// let HOST_NAME = '';
// if (parser.hostname != 'localhost') { HOST_NAME = parser.hostname; }
//
// if (parser.hostname == 'localhost' || parser.hostname == 'cnp1564.developer24x7.com') {
// 	// HOST_NAME = 'cnp1564.developer24x7.com';
// 	//HOST_NAME = process.env.APP_HOST_NAME; //'cnp1564uat.developer24x7.com';
// 	//BASE_URL = process.env.APP_BASE_URL; //"https://"+HOST_NAME;
// 	//SITE_URL = process.env.APP_SITE_URL; //"https://"+HOST_NAME+"/cnp302-front-end/dist";
// 	HOST_NAME = 'cnp1564-backend.test';
// 	BASE_URL = `http://${HOST_NAME}`;
// 	SITE_URL = 	`http://${HOST_NAME}/cnp302-front-end/dist`
// }
// else if (parser.hostname == 'cnp1564-frontend.developer24x7.com') {
// 	HOST_NAME = 'cnp1564uat.developer24x7.com';
// 	BASE_URL = "https://"+HOST_NAME;
// 	SITE_URL = "https://"+parser.hostname;
// }
// else if (parser.hostname == 'cnp1564uat-frontend.developer24x7.com') {
// 	HOST_NAME = 'cnp1564uat.developer24x7.com';
// 	BASE_URL = "https://"+HOST_NAME;
// 	SITE_URL = "https://"+parser.hostname;
// }
// else if (parser.hostname == '192.168.1.6') {
// 	HOST_NAME = '192.168.1.6';
// 	BASE_URL = "http://localhost:8000";
// 	SITE_URL = "http://192.168.1.6:8080";
// } else if (parser.hostname == '192.168.237.134') {
// 	HOST_NAME = '192.168.237.134';
// 	BASE_URL = "http://localhost:8000";
// 	SITE_URL = "http://192.168.237.134:8080";
// } else if (parser.hostname == '192.168.0.186') {
// 	HOST_NAME = '192.168.0.186';
// 	BASE_URL = "http://localhost:8000";
// 	SITE_URL = "http://192.168.0.186:8080";
// }else if (parser.hostname == '192.168.1.4') {
// 	HOST_NAME = '192.168.1.4';
// 	BASE_URL = "http://localhost:8000";
// 	SITE_URL = "http://192.168.1.4:8080";
// }
// else if (parser.hostname == '192.168.2.7') {
// 	HOST_NAME = '192.168.2.7';
// 	BASE_URL = "http://localhost:8000";
// 	SITE_URL = " http://192.168.2.7:8080";
// }
// else if (parser.hostname == '192.168.0.163') {
// 	HOST_NAME = '192.168.0.163';
// 	BASE_URL = "http://127.0.0.1:8000";
// 	SITE_URL = " http://192.168.0.163:8080";
// }
// else if (parser.hostname == '192.168.1.102') {
// 	HOST_NAME = '192.168.1.102';
// 	BASE_URL = "http://localhost:8000";
// 	SITE_URL = " http://192.168.1.102:8080";
// }
// else if (parser.hostname == '192.168.1.35') {
// 	HOST_NAME = '192.168.1.35';
// 	BASE_URL = "http://localhost:8000";
// 	SITE_URL = " http://192.168.1.35:8080";
// }
// else if (parser.hostname == '192.168.43.121') {
// 	HOST_NAME = '192.168.43.121';
// 	BASE_URL = "http://localhost:8000";
// 	SITE_URL = " http://192.168.43.121:8080";
// }
// else if (parser.hostname == '192.168.204.134') {
// 	HOST_NAME = '192.168.204.134';
// 	BASE_URL = "http://localhost:8000";
// 	SITE_URL = " http://192.168.204.134:8080";
// }
// else if (parser.hostname == '192.168.69.134') {
// 	HOST_NAME = '192.168.69.134';
// 	BASE_URL = "http://localhost:8000";
// 	SITE_URL = " http://192.168.69.134:8080";
// }
// else if (parser.hostname == '192.168.0.6') {
// 	HOST_NAME = '192.168.0.6';
// 	BASE_URL = "http://localhost:8000";
// 	SITE_URL = " http://192.168.0.6:8080";
// }
// else if (parser.hostname == '192.168.0.186') {
// 	HOST_NAME = '192.168.0.186';
// 	BASE_URL = "http://localhost:8000";
// 	SITE_URL = " http://192.168.0.186:8080";
// }
// else if (parser.hostname == '192.168.0.8') {
// 	HOST_NAME = '192.168.0.8';
// 	BASE_URL = "http://localhost:8000";
// 	SITE_URL = " http://192.168.0.8:8080";
// }
// else if (parser.hostname == '192.168.1.19') {
// 	HOST_NAME = '192.168.1.19';
// 	BASE_URL = "http://localhost:8000";
// 	SITE_URL = " http://192.168.1.19:8080";
// }
// else if (parser.hostname == '192.168.225.208'){
// 	HOST_NAME = '192.168.225.208';
// 	BASE_URL = "http://localhost:8000";
// 	SITE_URL = "http://192.168.225.208:8080";
// } else if (parser.hostname == '192.168.0.102'){
// 	HOST_NAME = '192.168.0.102';
// 	BASE_URL = "http://localhost:8000";
// 	SITE_URL = "http://192.168.0.102:8080";
// }
// else {
// 	HOST_NAME = '192.168.87.134';
// 	BASE_URL = "http://localhost:8000";
// 	SITE_URL = "http://192.168.87.134:8080";
// }
const config = {
    'BASE_URL': process.env.VUE_APP_SITE_URL,//SITE_URL,
    'VUE_APP_API_URL': process.env.VUE_APP_BASE_URL + "/api/",//BASE_URL + "/api/",
    'LARAVEL_SITE_URL' : process.env.VUE_APP_BASE_URL//BASE_URL
}

export default config
