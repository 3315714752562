import axios from 'axios';
import store  from "./store";
import config from './config/env';




  

const HTTP = axios.create({
    baseURL: config.BASE_URL,
});

// Add/Update token for each request
HTTP.interceptors.request.use(    
    config => {
        let authUser = localStorage.getItem("token");
        let change_request= localStorage.getItem('change_request');
        if (authUser) {
            config.headers.authorization = "Bearer " + authUser;
            config.headers.Accept = "application/json";
            //config.headers.paths = window.location.href;
            //config.headers.get["Accept"] = "*/*";
            // if (config.method === 'post' || config.method === 'POST') {
            //     // Use method overriding with _method=PUT
            //     // config.data = `_method=PUT&${config.data}`;
            //     if(change_request) {
            //         console.log('change_request123', change_request)
            //        // Use method overriding with _method=PUT
            //         config.data = `_method=PUT&${config.data}`;
            //     }
                
            // }
            //config.headers.get["Content-Type"] = "application/zip; charset=utf-8";
            // config.headers.responseType = 'arraybuffer';
            // config.headers.get["Content-Disposition"] = "attachment";
            // console.log(config.headers);
            // 'Content-Type': 'application/json'
            //config.headers.responseType = 'blob';
            //config.params = {api_token: authUser};      
        }
        
        return config;
    },
    // Do something with request error
    error => {
        Promise.reject(error)}
);

// Add a 401 response interceptor
HTTP.interceptors.response.use( (response)=> {
    return response;
},  (error)=> {
        if(error.response && error.response.status && error.response.status == 401){
            store.dispatch("logout");
            this.$router.push({'name':'login'});
        }else if(error.response && error.response.status && error.response.status == 403) {
            // console.log(error.response.data.message);
            // alert(
            //     error.response.data.message
            // )
            
            // this.$swal('Hello Vue world!!!');
            
            var modal = document.getElementById('customDialog');
            var span = document.getElementById('closeModal');
            var messageParagraph = document.getElementById('modal-message');

            messageParagraph.textContent = error.response.data.message;
            modal.style.display = 'block';

            span.onclick = function() {
                modal.style.display = 'none';
            };

            return false;
        } else if(error.response && error.response.status && error.response.status == 500) {
            var modal = document.getElementById('customDialog');
            var span = document.getElementById('closeModal');
            var messageParagraph = document.getElementById('modal-message');

            messageParagraph.textContent = error.response.data.message;
            modal.style.display = 'block';

            span.onclick = function() {
                modal.style.display = 'none';
            };

            return false;
        }
        return Promise.reject(error);
});

export default HTTP;