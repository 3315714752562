import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// const auth = {
  // namespaced: true,
  const state= {
    pickAppointmentDate: new Date(),
    sideBarExpended: true,
    selectedCalendarFilter: null,
    selectedInvoiceFilter: null,
    selectedTab: null,
    selectedStudentId: null,
    selectedStudentObj: null,
    selectedPayerName: '',
    editModalData: null,
    signerFormFields: [],
    activateNextStep: false,
    fileSideBarList: [],
    locationIdentifier: null,
    fileManagementLeftContent: null,
    userDocumentLabelData: null,
    userDocumentLabelIsUpdated: false,
    staffDocumentFormData: null,
    labelFormFieldsData: null
  };
  const mutations= {
    setAppointmentDate(state, payload) {
      state.pickAppointmentDate=payload;
    //   console.log(state.pickAppointmentDate);
    },
    setSideBarExpended(state, payload) {
      state.sideBarExpended=payload;
    //   console.log(state.pickAppointmentDate);
    },
    setCalendarFilters(state, payload) {
      state.selectedCalendarFilter=payload;
    //   console.log(state.pickAppointmentDate);
    },
    setInvoiceFilters(state, payload) {
      state.selectedInvoiceFilter=payload;
    //   console.log(state.pickAppointmentDate);
    },
    setSelectedTab(state, payload) {
      state.selectedTab=payload;
    //   console.log(state.pickAppointmentDate);
    },
    setSelectedStudentId(state, payload) {
      state.selectedStudentId = payload;
    },
    setSelectedStudentObj(state, payload) {
      state.selectedStudentObj = payload;
    },
    setSelectedPayerName(state,payerName) {
      state.selectedPayerName = payerName
    },
    setEditModalData(state,payload) {
      state.editModalData = payload;
    },
    setSignerFormFields(state,payload) {
      state.signerFormFields = payload;
    },
    setActivateNextStep(state,status) {
      state.activateNextStep = status;
    },
    setFileSideBarList(state, payload) {
      state.fileSideBarList = payload;
    },
    setLocationIdentifier(state, payload) {
      state.locationIdentifier = payload;
    },
    setFileManagementLeftContent(state, payload) {
      state.fileManagementLeftContent = payload;
    },
    setUserDocumentLabelData(state,payload) {
      state.userDocumentLabelData = payload;
    },
    setUserDocumentLabelIsUpdated(state,status) {
      // 
      state.userDocumentLabelIsUpdated = status;
    },
    setStaffDocumentFormData(state,payload) {
      state.staffDocumentFormData = payload;
    },
    setLabelFormFieldsData(state,payload) {
      state.labelFormFieldsData = payload;
    }
  };
  const actions= {
    setAppointmentDate({commit}, data) {
      commit('setAppointmentDate', data);
    },
    setSideBarExpended({commit}, value) {
      commit('setSideBarExpended', value);
    },
    setCalendarFilters({commit}, value) {
      commit('setCalendarFilters', value);
    },
    setInvoiceFilters({commit}, value) {
      commit('setInvoiceFilters', value);
    },
    setSelectedTab({commit}, value) {
      commit('setSelectedTab', value);
    },
    setSelectedStudentId({commit}, value) {
      commit('setSelectedStudentId', value);
    },
    setSelectedStudentObj({commit}, obj) {
      commit('setSelectedStudentObj', obj);
    },
    setSelectedPayerName({commit}, value) {
      commit('setSelectedPayerName', value);
    },
    setEditModalData({commit}, payload) {
      commit('setEditModalData', payload)
    },
    setActivateNextStep({commit},status) {
      commit('setActivateNextStep', status);
    },
    setSignerFormFields({commit,dispatch},payload) {
      let status = false;
      commit('setSignerFormFields', payload);
      if(payload.length) {
        status = false;
      }
      // console.log("Status:", status);
      dispatch('setActivateNextStep', status);
    },
    setFileSideBarList({commit},payload) {
      commit('setFileSideBarList', payload);
    },
    setLocationIdentifier({commit},payload) {
      commit('setLocationIdentifier', payload);
    },
    setFileManagementLeftContent({commit}, payload) {
      commit('setFileManagementLeftContent', payload);
    },
    setUserDocumentLabelData({ commit }, payload) {
      commit('setUserDocumentLabelData', payload);
    },
    setUserDocumentLabelIsUpdated({ commit }, status) {
      commit('setUserDocumentLabelIsUpdated', status);
    },
    setStaffDocumentFormData({ commit },payload) {
      commit('setStaffDocumentFormData',payload);
    },
    setLabelFormFieldsData({commit},payload) {
      commit('setLabelFormFieldsData', payload);
    }    
  };
  const getters= { 
    pickAppointmentDate: state => state.pickAppointmentDate,
    isExpendedSideBar: state => state.sideBarExpended,
    selectedCalendarFilters: state => state.selectedCalendarFilter,
    selectedInvoiceFilters: state => state.selectedInvoiceFilter,
    selectedTab: state => state.selectedTab,
    selectedStudentId: state => state.selectedStudentId,
    selectedStudentObj: state => state.selectedStudentObj,
    selectedPayerName: state => state.selectedPayerName,
    editModalData: state => state.editModalData,
    signerFormFields: state => state.signerFormFields,
    activateNextStep: state => state.activateNextStep,
    fileSideBarList: state => state.fileSideBarList,
    locationIdentifier: state => state.locationIdentifier,
    fileManagementLeftContent: state => state.fileManagementLeftContent,
    userDocumentLabelData: state => state.userDocumentLabelData,
    userDocumentLabelIsUpdated: state => state.userDocumentLabelIsUpdated,
    staffDocumentFormData: state => state.staffDocumentFormData,
    labelFormFieldsData: state => state.labelFormFieldsData
  }
// }

// export default auth;

export default {
  state,
  getters,
  actions,
  mutations
}
