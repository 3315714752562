import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// const auth = {
  // namespaced: true,
  const state= {
    authUser:null,
    routeActive: null,
    userAccess: true,
    currentPermittedMethods: null
    // user: localStorage.getItem('user'),
    // token: localStorage.getItem('token'),
    // userPermission: localStorage.getItem('userPermission'),
    // isLoggedIn: localStorage.getItem('isLoggedIn'),
    // userType: localStorage.getItem('userType'),
  };
  const mutations= {
    setToken(state, payload) {
      state.authUser=payload;
      localStorage.setItem('token', payload.token);
      localStorage.setItem('userPermission', JSON.stringify(payload.userPermission));
      //localStorage.setItem('user',  JSON.stringify(payload.user));
      localStorage.setItem('isLoggedIn', true);
      localStorage.setItem('userType', JSON.stringify(payload.userType));
      // this.commit('auth/setDetails',state);
    },
    logout(state){
      state.authUser=null
      localStorage.setItem('token', '');
      localStorage.setItem('userPermission', '');
      //localStorage.setItem('user', '');
      localStorage.setItem('isLoggedIn', '');
      localStorage.setItem('userType', '');
      // this.commit('auth/setDetails',state);
    },
    setRouteActive(state, data) {
      state.routeActive = data;
    },

    setUserAccess(state, data) {
      state.userAccess = data;
    },

    setPermission(state, data) {
      state.currentPermittedMethods = data;
    }

    // setDetails(state){
    //   //state.user = localStorage.getItem('user');
    //   state.token = localStorage.getItem('token');
    //   state.userPermission = localStorage.getItem('userPermission');
    //   state.isLoggedIn = localStorage.getItem('isLoggedIn');
    //   state.userType = localStorage.getItem('userType');
    // }
  };
  const actions= {
    logout({commit}) {
      commit('logout');
    },

    getModuleSelectedMethods(context, payload) {
      let currentPermittedMethods = _.filter(context.state.authUser.userPermission, [payload.label, payload.labelName]);
      context.commit('setPermission', currentPermittedMethods);
    },

  };
  const getters= { 
    isLoggedIn: state => state.authUser?true:false,
    getToken: state => state.authUser?state.authUser.token:null,
    isSuperAdmin: state => state.authUser && (state.authUser.userType == 'Super Admin') ?true:false,
    userType: state => state.authUser?state.authUser.userType:null,
    userPermission: state => state.authUser?state.authUser.userPermission:null,
    loggedInUserId: state => state.authUser?state.authUser.userId:null,
    loggedInUserName: state => state.authUser?state.authUser.userName:null,
    loggedInUserEmail: state => state.authUser?state.authUser.userEmail:null,
    routeActive: state => state.routeActive,
    isMethodPermitted: (state) => (method_name) => {
      if (state.authUser && state.authUser.userType === 'Super Admin') {
        return true;
      } else {
        let find_value = _.find(state.currentPermittedMethods, ['method', method_name]);
        return !!find_value;
      }
    },
    isSublabelPermitted: (state) => (sub_label_name) => {
      if (state.authUser && state.authUser.userType === 'Super Admin') {
        return true;
      } else {
        let find_value = _.find(state.currentPermittedMethods, ['subLabel', sub_label_name]);
        return !!find_value;
      }
    },
    isStudentSublabelPermitted: (state) => (sub_label_name, sub_label_list = null) => {
      if (state.authUser && state.authUser.userType === 'Super Admin') {
        return true;
      } else {
        let find_value = _.filter(state.authUser.userPermission, ['subLabel', sub_label_name]);
        let methods = _.find(find_value, ['method', sub_label_list]);
        // console.log('find_value', find_value);
        if(find_value && sub_label_list == null) {
          return true;
        } else if(methods) {
          return true;
        } else {
          return false;
        }
      }
    },
    // getToken(state){
    //   return state.token;
    // },
    // getuserPermission(state){
    //   return state.userPermission;
    // },
    // getLoggedIn(state){
    //   return state.isLoggedIn;
    // },
    // getuserType(state){
    //   return state.userType;
    // },
  }
// }

// export default auth;

export default {
  state,
  getters,
  actions,
  mutations
}
