<template>
    <div id="content-body">
        <div class="py-5 text-center w-100">
            <div class="mx-auto w-xxl w-auto-xs">
                <div class="px-3">
                    <div>
                        <h5>Change your password?</h5>
                        <p class="text-muted my-3">
                        </p>
                    </div>
                    <form  name="form" @submit.prevent="changePassword">
                        <div class="form-group">
                            <input type="email" placeholder="Email" class="form-control" v-model="form.email">
                        </div>
                        <div class="form-group">
                            <input type="password" placeholder="New Password" class="form-control" v-model="form.password">
                        </div>
                        <div class="form-group">
                            <input type="password" placeholder="Confirm Password" class="form-control" v-model="form.confirm_password">
                        </div>
                        <button type="submit" class="btn primary btn-block p-x-md">Send</button>
                    </form>
                    <div class="py-4">
                        Return to
                        <router-link :to="{name : 'home'}" class="text-primary _600">
                          Sign in
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions,mapGetters,mapState } from 'vuex';
import Api from '../../../service/api';
// import { setTimeout } from 'timers';

export default {
    components: {
    },
    data () {
        return {
            form: {
                password: '',
                confirm_password:'',
                email:'',
                token:''
                
            }
        }
    },
    
    created() {
        
    },

    mounted() {
        
    },

   
    methods: {        
        changePassword(){            
            this.form.token = this.$router.currentRoute.params.token;
            //console.log(this.form);
            return Api.changePassword(this.form)
            .then(response => {                
                this.$swal({ title: response.data.message, type: "success" });
            })
            .catch(error => {
                //console.log(error);
                this.$swal({ title: error.response.data.message, type: "warning" });
            })
        }
    }
}
</script>

