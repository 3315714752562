<template>
    <div class="app">
        <profile-sidebar-layout :show_div_type="isExpendedSideBar" v-if="sidebar=='profile'"></profile-sidebar-layout>
        <!-- <profile-sidebar-mobile :show_div_type="!isExpendedSideBar" v-if="sidebar=='profile'"></profile-sidebar-mobile> -->
        <div id="main-content" class="app-content box-shadow-0" :class="header" role="main">
            <header-layout :header_type = "header"></header-layout>
                <router-view :key="$route.fullPath"></router-view> 
        </div>
        <footer-layout></footer-layout>
    </div>
</template>

<script>

import ProfileSidebarLayout from "../components/protected/layout/ProfileSidebar";
import ProfileSidebarMobile from "../components/protected/layout/ProfileSidebarMobile";
import FooterLayout from "../components/protected/layout/Footer";
import HeaderLayout from "../components/protected/layout/Header";
import { mapGetters } from "vuex";

export default {
    name: 'ProtectedVue',
    components: {
        ProfileSidebarLayout,
        ProfileSidebarMobile,
        FooterLayout,
        HeaderLayout
    },
    mounted() {
        this.geolocate();
    },
    
    computed: {
        ...mapGetters({
          isExpendedSideBar: "isExpendedSideBar"
        })
    },

    data() {
        return {
            show_div : true,
            show_div_mobile : false,
            center: { lat: null, lng: null },

        }
    },
    props: {
        header: {
            type: String
        },
        sidebar : {
            type : String
        }
    },
    watch: {
        center: {
            handler: function (val, oldVal) {
                this.$store.dispatch("setLocationIdentifier", this.center);
            },
            deep: true
        },
    },

    methods: {
        geolocate: function() {
            navigator.geolocation.getCurrentPosition(position => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
            });
        },
    },
}
</script>