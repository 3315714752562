<template>
  <div>
    <button @click.prevent="captureAndProcessScreen" type="button" class="btn-outline-primary btn-block mx-0" v-if="visible">
      <i class="fa fa-camera mr-3" aria-hidden="true"></i>
      Capture screenshot
    </button>
  </div>
</template>
<script>
import {capture, dataURLtoFile, eventBus} from "@/helpDesk";

export default {
  name: "BrowserCaptureImage",
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: null
    }
  },
  computed: {
    modelValue: {
      get() { return this.value; },
      set(value) { this.$emit('input', value); }
    }
  },
  methods: {
    captureAndProcessScreen() {
      this.$store.commit('setHelDeskFormCapturing', true);
      capture()
          .then((screenShot) => {
            this.$store.commit('setHelpDeskFormScreenShot', screenShot)
          }).finally(() => {
            this.$store.commit('setHelDeskFormCapturing', false);
          })
    },
  }
}
</script>

<style scoped>

</style>