import Vue from "vue"
import moment from "moment/moment";
import Api from "@/service/api";
import _ from "lodash";
import httpErrorHandler from "@/httpErrorHandler";
import {downloadFile} from "@/service/helpers";

let debounced = {
    fetchFilters: null,
    fetchStudents: null,
    fetchBillingCodes: null,
    fetchServices: null,
    fetchServiceLines: null,
    fetchStudentMainList: null,
    updateImportCountDown: null
};

const state = {
    export: {
        exportStudentAuthData: {
            isLoading: false
        }
    },
    showImportMsg: {
        isShow: false,
        dismissCountDown: 0,
        count: 0,
        runnerCount: 0,
    },
    modals: {
        showExportModal: false,
        showImportModal: false,
        showImportAuthModal: false,
        showLoadingModal: false
    },
    masterData: {
        agency_name: {
            isLoading: false,
            data: [],
            error: null
        },
        funder_name: {
            isLoading: false,
            data: [],
            error: null
        },
        staff_name: {
            isLoading: false,
            data: [],
            error: null
        },
        service: {
            isLoading: false,
            data: [],
            error: null
        },
        service_line: {
            isLoading: false,
            error: null,
            data: []
        },
        students: {
            isLoading: false,
            error: null,
            data: []
        },
        billing_codes: {
            isLoading: false,
            error: null,
            data: []
        },
        monthOptions: {
            isLoading: false,
            error: null,
            data: [
                {"name":"Jan", "value":"1"},
                {"name":"Feb", "value":"2"},
                {"name":"Mar", "value":"3"},
                {"name":"Apr", "value":"4"},
                {"name":"May", "value":"5"},
                {"name":"Jun", "value":"6"},
                {"name":"Jul", "value":"7"},
                {"name":"Aug", "value":"8"},
                {"name":"Sep", "value":"9"},
                {"name":"Oct", "value":"10"},
                {"name":"Nov", "value":"11"},
                {"name":"Dec", "value":"12"}
            ]
        },
        statusOptions: {
            isLoading: false,
            error: null,
            data: [
                {"name":"Active", "value": 1},
                {"name":"Inactive", "value": 0}
            ]
        },
    },
    filterData: {
        agency_name: null,
        student_name: null,
        funder_name: null,
        staff_name: null,
        authorization_start_date: null,
        authorization_end_date: null,
        dob_month: null,
        dob_year: null,
        status: null,
        serviceLine: null,
        service: null,
        billingCode: null
    },
    filterDataEnabledAction: false,
    sortingData: {
        isDescEnabled: true,
        orderByColumn: '',
        orderDirection: 'desc'
    },
    students: {
        newStudentsAvailable: false,
        isLoading: false,
        total: 0,
        currentPage: 1,
        lastPage: 1,
        perPage: 50,
        pageOptions: [50,100,150,200,500],
        startFrom: null,
        endTo: null,
        data: []
    }
};
const mutations = {
    /*
      |-----------------------------------------------------------------
      | Export
      |-----------------------------------------------------------------
     */
    toggleExportStudentAuthDataIsLoading(state, payload) {
        state.export.exportStudentAuthData.isLoading = !!payload;
    },
    /*
      |-----------------------------------------------------------------
      | Show import message
      |-----------------------------------------------------------------
     */
    toggleShowImportMsgIsShow(state, payload) {
        state.showImportMsg.isShow = !!payload;
    },
    setShowImportMsgDismissCountDown(state, payload) {
        state.showImportMsg.dismissCountDown = payload;
    },
    setShowImportMsgCount(state, payload) {
        state.showImportMsg.count = payload;
    },
    setShowImportMsgRunnerCount(state, payload) {
        state.showImportMsg.runnerCount = payload;
    },
    /*
      |-----------------------------------------------------------------
      | Filter and sorting data
      |-----------------------------------------------------------------
     */
    setFilterData(state, payload) {
        state.filterData = {
            ...state.filterData,
            ...payload
        };
    },
    setSortData(state, payload) {
        state.sortingData = {
            ...state.sortingData,
            ...payload
        };
    },
    /*
      |-----------------------------------------------------------------
      | Toggle modals
      |-----------------------------------------------------------------
     */
    toggleShowExportModal(state, payload) {
        state.modals.showExportModal = !!payload;
    },
    toggleShowImportModal(state, payload) {
        state.modals.showImportModal = !!payload;
    },
    toggleShowImportAuthModal(state, payload) {
        state.modals.showImportAuthModal = !!payload;
    },
    toggleShowLoadingModal(state, payload) {
        state.modals.showLoadingModal = !!payload;
    },

    /*
      |-----------------------------------------------------------------
      | Student table data
      |-----------------------------------------------------------------
     */
    toggleStudentIsLoading(state, payload) {
      state.students.isLoading = !!payload;
    },
    toggleStudentNewDataAvailable(state, payload) {
        state.students.newStudentsAvailable = !!payload;
    },
    setStudentPagination(state, payload) {
        state.students.total = payload?.total || state.students.total;
        state.students.currentPage = payload?.currentPage || state.students.currentPage;
        state.students.lastPage = payload?.lastPage || state.students.lastPage;
        state.students.perPage = payload?.perPage || state.students.perPage;
        state.students.startFrom = payload?.startFrom || state.students.startFrom;
        state.students.endTo = payload?.endTo || state.students.endTo;
    },
    setStudentData(state, payload) {
        state.students.data = [...payload];
    },
    /*
      |-----------------------------------------------------------------
      | Master data: Students
      |-----------------------------------------------------------------
     */
    toggleMasterStudentsIsLoading(state, payload) {
        state.masterData.students.isLoading = !!payload;
    },
    setMasterStudentsErrorMsg(state, payload) {
        state.masterData.students.error = payload;
    },
    setMasterStudentsData(state, payload) {
        state.masterData.students.data = [...payload];
    },
    /*
     |-----------------------------------------------------------------
     | Master data: funder, staff and agency
     |-----------------------------------------------------------------
    */
    toggleMasterFunderStaffAndAgencyIsLoading(state, payload) {
        state.masterData.funder_name.isLoading = !!payload;
        state.masterData.staff_name.isLoading = !!payload;
        state.masterData.agency_name.isLoading = !!payload;
    },
    setMasterFunderStaffAndAgencyErrorMsg(state, payload) {
        state.masterData.funder_name.error = payload;
        state.masterData.staff_name.error = payload;
        state.masterData.agency_name.error = payload;
    },
    setMasterFunderStaffAndAgencyData(state, {funders, staffs, agencies}) {
        state.masterData.funder_name.data = [...funders];
        state.masterData.staff_name.data = [...staffs];
        state.masterData.agency_name.data = [...agencies];
    },
    /*
     |-----------------------------------------------------------------
     | Master data: Service
     |-----------------------------------------------------------------
    */
    toggleMasterServicesIsLoading(state, payload) {
        state.masterData.service.isLoading = !!payload;
    },
    setMasterServicesErrorMsg(state, payload) {
        state.masterData.service.error = payload;
    },
    setMasterServicesData(state, payload) {
        state.masterData.service.data = [...payload];
    },
    /*
     |-----------------------------------------------------------------
     | Master data: Service Line
     |-----------------------------------------------------------------
    */
    toggleMasterServiceLinesIsLoading(state, payload) {
        state.masterData.service_line.isLoading = !!payload;
    },
    setMasterServiceLinesErrorMsg(state, payload) {
        state.masterData.service_line.error = payload;
    },
    setMasterServiceLinesData(state, payload) {
        state.masterData.service_line.data = [...payload];
    },
    /*
     |-----------------------------------------------------------------
     | Master data: Billing code
     |-----------------------------------------------------------------
    */
    toggleMasterBillingCodesIsLoading(state, payload) {
        state.masterData.billing_codes.isLoading = !!payload;
    },
    setMasterBillingCodesErrorMsg(state, payload) {
        state.masterData.billing_codes.error = payload;
    },
    setMasterBillingCodesData(state, payload) {
        state.masterData.billing_codes.data = [...payload];
    },

};
const actions = {
    fetchStudents(context, payload = null) {
        if (debounced.fetchStudentMainList) {
            debounced.fetchStudentMainList.cancel();
        }
        context.commit('toggleStudentNewDataAvailable', false);
        context.commit('setSortData', {
            orderByColumn: payload?.sortBy || '',
            orderDirection: payload?.sortDirection || 'desc',
        });
        context.commit('setStudentPagination', {
            currentPage: payload?.currentPage || 1,
            perPage: payload?.perPage || 50,
        });
        let formData = context.getters.getApiFilters;
        const params = {
            page: context.state.students.currentPage,
            perPage: context.state.students.perPage
        };
        context.commit('toggleStudentIsLoading', true);
        debounced.fetchStudentMainList = _.debounce(() => {
            Api.getStudents(params, formData, context.getters.getApiSorting)
                .then( response => {
                    context.commit('setStudentPagination', {
                        currentPage: response.data.data.current_page,
                        lastPage: response.data.data.last_page,
                        perPage: response.data.data.per_page,
                        total: response.data.data.total,
                        startFrom: response.data.data.from,
                        endTo: response.data.data.to
                    });
                    context.commit('setStudentData', [...response.data.data.data]);
                })
                .catch(e => { Vue.swal({ title: httpErrorHandler(e), type: "error" }); })
                .finally(() => { context.commit('toggleStudentIsLoading', false); })
        }, 500);
        debounced.fetchStudentMainList();

    },
    fetchOtherFilterMasterData(context) {
        if (debounced.fetchFilters) {
            debounced.fetchFilters.cancel();
        }
        debounced.fetchFilters = _.debounce(() => {
            context.commit('toggleMasterFunderStaffAndAgencyIsLoading', true);
            context.commit('setMasterFunderStaffAndAgencyErrorMsg', null);
            Api.studentFilters()
                .then( response => {
                    context.commit('setMasterFunderStaffAndAgencyData', {
                        funders: [...response.data.funders],
                        staffs: [...response.data.staffs],
                        agencies: [...response.data.agencies]
                    });
                })
                .catch(e => { context.commit('setMasterFunderStaffAndAgencyErrorMsg', httpErrorHandler(e)); })
                .finally(() => { context.commit('toggleMasterFunderStaffAndAgencyIsLoading', false); })
        }, 500);
        debounced.fetchFilters();
    },
    searchStudents(context, payload) {
        if (debounced.fetchStudents) {
            debounced.fetchStudents.cancel();
        }
        debounced.fetchStudents = _.debounce((query) => {
            context.commit('toggleMasterStudentsIsLoading', true);
            context.commit('setMasterStudentsErrorMsg', null);
            Api.getAllStudents(query)
                .then( response => { context.commit('setMasterStudentsData', [...response.data.data]); })
                .catch(e => {  context.commit('setMasterStudentsErrorMsg', httpErrorHandler(e));})
                .finally(() => { context.commit('toggleMasterStudentsIsLoading', false); })
        }, 500);
        debounced.fetchStudents(payload?.search || '')
    },
    searchBillingCodes(context, payload) {
        if (debounced.fetchBillingCodes) {
            debounced.fetchBillingCodes.cancel();
        }
        debounced.fetchBillingCodes = _.debounce((query) => {
            context.commit('toggleMasterBillingCodesIsLoading', true);
            context.commit('setMasterBillingCodesErrorMsg', null);
            Api.getBillingCodesForDropdown(query)
                .then( response => { context.commit('setMasterBillingCodesData', [...response.data.data]);  })
                .catch(e => { context.commit('setMasterBillingCodesErrorMsg', httpErrorHandler(e)); })
                .finally(() => { context.commit('toggleMasterBillingCodesIsLoading', false); })
        }, 500);
        debounced.fetchBillingCodes(payload?.search || '');
    },
    searchServices(context, payload) {
        if (debounced.fetchServices) {
            debounced.fetchServices.cancel();
        }
        debounced.fetchServices = _.debounce((query) => {
            context.commit('toggleMasterServicesIsLoading', true);
            Api.getServicesForDropdown(query)
                .then( response => { context.commit('setMasterServicesData', [... response.data.data]) })
                .catch(e => { context.commit('setMasterServicesErrorMsg', httpErrorHandler(e)); })
                .finally(() => { context.commit('toggleMasterServicesIsLoading', false); })
        }, 500);
        debounced.fetchServices(payload?.search || '');
    },
    searchServiceLines(context, payload) {
        if (debounced.fetchServiceLines) {
            debounced.fetchServiceLines.cancel();
        }
        debounced.fetchServiceLines = _.debounce((query) => {
            context.commit('toggleMasterServiceLinesIsLoading', true);
            context.commit('setMasterServiceLinesErrorMsg', null);
            Api.getServiceLinesForDropdown(query)
                .then( response => { context.commit('setMasterServiceLinesData', [...response.data.data]) })
                .catch(e => { context.commit('setMasterServiceLinesErrorMsg', httpErrorHandler(e)); })
                .finally(() => { context.commit('toggleMasterServiceLinesIsLoading', false); })
        }, 500);
        debounced.fetchServiceLines(payload?.search || '');
    },
    updateImportCountDown(context) {
        if (debounced.updateImportCountDown) {
            debounced.updateImportCountDown.cancel();
        }
        debounced.updateImportCountDown = _.debounce(() => {
            Api.getImportStudentCount()
                .then( response => {
                    if(_.toNumber(response.data) !== 0 ) {
                        context.commit('setShowImportMsgCount', _.toNumber(response.data));
                        context.commit('toggleShowImportMsgIsShow', true);
                        context.commit('setShowImportMsgDismissCountDown', 10);
                        context.commit('toggleStudentNewDataAvailable', true);
                        // setTimeout(() => {
                        //     this.loadData();
                        //     this.getImportedStudentCount();
                        // }, 30000);
                    } else {
                        context.commit('toggleShowImportMsgIsShow', false);
                    }
                })
                .catch(e => {
                    Vue.swal({ title: httpErrorHandler(e), type: "error" });
                })
                .finally(() => {
                    context.commit('setShowImportMsgRunnerCount', (context.state.showImportMsg.runnerCount + 1));
                });
        }, 500);
    },
    resetFilters(context) {
        context.commit('setFilterData', {
            agency_name: null,
            student_name: null,
            funder_name: null,
            staff_name: null,
            authorization_start_date: null,
            authorization_end_date: null,
            dob_month: null,
            dob_year: null,
            status: null,
            serviceLine: null,
            service: null,
            billingCode: null
        });
        context.commit('setSortData', {
            orderByColumn: '',
            orderDirection: 'desc'
        });
        context.dispatch('fetchStudents');
    },
    exportStudents(context) {
        context.commit('toggleShowLoadingModal', true)
        Api.downloadAllStudents(context.getters.getApiFilters, context.getters.getApiSorting)
            .then( response => { window.open( window.location = response.data); })
            .catch(e => { Vue.swal({ title: httpErrorHandler(e), type: "error" }); })
            .finally(() => { context.state.modals.showLoadingModal = false; })
    },
    exportStudentAuthData(context) {
        context.commit('toggleExportStudentAuthDataIsLoading', true);
        Api.exportAuthData(context.getters.getApiFilters)
            .then((response) => {
                downloadFile(response.data, 'export-auth-data.xlsx');
            })
            .catch((e) => {
                Vue.swal({ title: httpErrorHandler(e), type: "error" });
            })
            .finally(() => {
                context.commit('toggleExportStudentAuthDataIsLoading', false);
            });
    },
};

const getters = {
    getApiFilters: (state) => {
        return {
            staff_name: state.filterData.staff_name ? state.filterData.staff_name.name : '',
            student_id: state.filterData.student_name && state.filterData.student_name.id ? state.filterData.student_name.id : '',
            funder_name: state.filterData.funder_name ? state.filterData.funder_name.name : '',
            agency_name: state.filterData.agency_name ? state.filterData.agency_name.name : '',
            status: state.filterData.status ? state.filterData.status.value : '',
            authorization_start_date: state.filterData.authorization_start_date ? moment(state.filterData.authorization_start_date).format('YYYY-MM-DD') : '',
            authorization_end_date: state.filterData.authorization_end_date ? moment(state.filterData.authorization_end_date).format('YYYY-MM-DD') : '',
            dob_month: state.filterData.dob_month ? state.filterData.dob_month.value : '',
            dob_year: state.filterData.dob_year ? state.filterData.dob_year : '',
            serviceLine: state.filterData.serviceLine ? state.filterData.serviceLine.name : '',
            service: state.filterData.service ? state.filterData.service.name : '',
            billingCode: state.filterData.billingCode ? state.filterData.billingCode.name : ''
        }
    },
    getApiSorting: (state) => {
        return {
            sort_by: state.sortingData.orderDirection ? 'desc' : 'asc',
            sort_field_name: state.sortingData.orderByColumn
        };
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
