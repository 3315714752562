<script>
import * as markerjs2 from 'markerjs2';
import {mapState} from "vuex";

export default {
  name: "HelpDeskEditScreenshot",
  data() {
    return {
      markerArea: null
    };
  },
  computed: {
    ...mapState({
      imageData: (state) => state.help_desk.helpDeskFormScreenshot,
      previousState: (state) => state.help_desk.helpDeskFormScreenshotEditorState
    })
  },
  methods: {
    updateImage(event) {
      this.$store.commit('setHelpDeskFormEditedScreenShot', event.dataUrl)
      this.$store.commit('setHelpDeskFormScreenshotEditorState', event.state);
    },
    closeEditor() {
      this.$store.commit('setHelpDeskFormEnableScreenshotEdit', false);
    }
  },
  mounted() {
    if (this.$refs.captured.src) {
      this.$refs.captured.src = this.imageData;
      this.markerArea = new markerjs2.MarkerArea(this.$refs.captured);
      this.markerArea.uiStyleSettings.zIndex = 999999999;
      //this.markerArea.settings.displayMode = "popup";
      this.markerArea.addEventListener('render', this.updateImage);
      this.markerArea.addEventListener('close', this.closeEditor)
      this.markerArea.show();
      if (this.previousState) {
        this.markerArea.restoreState(this.previousState);
      }
    }

  },
  beforeDestroy() {
    // if (this.markerArea) {
    //   this.markerArea.removeEventListener('render', this.updateImage);
    //   this.markerArea.removeEventListener('close', this.closeEditor);
    //   this.markerArea = null;
    // }
  }
}
</script>

<template>
  <div class="app-helpdesk-editor">
    <div class="app-helpdesk-editor-image-area">
      <div class="editor-area">
        <img :src="imageData" alt="Capture screen" ref="captured" />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>