import {UAParser} from "ua-parser-js";
import Vue from "vue";

/*
 | User agent
 |----------------------
 | Additional information including user agent data for feedback.
 */
export const userAgentDetails = () => {
    const useNavigator = window.navigator;
    const userAgent = useNavigator.userAgent;
    const queryString = window.location.search;
    const { browser, cpu, device } = UAParser(userAgent);
    const pluginNames = [];
    if (useNavigator?.plugins) {
        const pluginsLength=useNavigator?.plugins?.length;
        if (pluginsLength) {
            for(let pluginIterator=0;pluginIterator<pluginsLength;pluginIterator++)
            {
                pluginNames.push(useNavigator?.plugins[pluginIterator]?.name );
            }
        }
    }
    return [
        {label: "App code name", value: userAgent?.appCodeName || "NA"},
        {label: "App name", value: userAgent?.appName || "NA"},
        {label: "User agent", value: userAgent || "NA"},
        {label: "Connection type", value: useNavigator?.connection?.type || "NA"},
        {label: "Connection effective type", value: useNavigator?.connection?.effectiveType || "NA"},
        {label: "Connection download link", value: useNavigator?.connection?.downlink || "NA"},
        {label: "Connection save data", value: useNavigator?.connection?.saveData || "NA"},
        {label: "PDF viewer enabled", value: useNavigator?.pdfViewerEnabled || "NA"},
        {label: "Java enabled", value: useNavigator?.javaEnabled() || "NA"},
        {label: "Plugin names", value: pluginNames.join(', ') || 'NA'},
        {label: "Platform", value: useNavigator?.platform || "NA"},
        {label: "Browser name", value: browser?.name || "NA"},
        {label: "Browser version", value: browser?.version || "NA"},
        {label: "CPU architecture", value: cpu?.architecture || "NA"},
        {label: "Device vendor", value: device?.vendor || "NA"},
        {label: "Device model", value: device?.model || "NA"},
        {label: "Device type", value: device?.type || "NA"},
        {label: "Cookies enabled", value: useNavigator.cookieEnabled || "NA"},
        {label: "URL", value: window.location.href || "NA"},
        {label: "Query string", value: queryString || "NA"},
    ];
}
export const userAgentBrowser = () => {
    const userAgent = window.navigator.userAgent;
    const { browser, cpu, device } = UAParser(userAgent);
    return {
        browserName: (browser.name || '').toLowerCase(),
        browserVersion: parseInt(browser?.major || 0)
    };
};

/*
 | Screen capture
 | -----------------------------------------
 | We can do the same thing with Html2Canvas but there has a drawback,
 | shadow dom is ignored by HTML2Canvas but we need full screenshot including all dom
 | for feedback. So, we are using screenshare and capturing that by canvas and video
 | element of HTML 5 which is far better solution then Html2Canvas for our use case.
 */

const getScreenshot = (track,videoElem) => {
    const canvas = document.createElement("canvas");
    const { width, height } = track.getSettings();
    canvas.width = width || window.innerWidth;
    canvas.height = height || window.innerHeight;
    canvas.getContext("2d")?.drawImage(videoElem, 0, 0);
    const image = canvas.toDataURL("image/png", 1);
    return image;
}
const videoPreview = async (videoElem) => {
    videoElem.srcObject = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: false,
        preferCurrentTab: true,
        // selfBrowserSurface: "exclude",
        // systemAudio: "include",
        // surfaceSwitching: "include",
        // monitorTypeSurfaces: "include",
    });
    return videoElem.srcObject;
}
const captureScreenShot = async (videoElem) => {
    return new Promise(async (resolve, reject) => {
        try {
            const captureStream = await videoPreview(videoElem);
            setTimeout(() => {
                const frame = getScreenshot(captureStream.getVideoTracks()[0], videoElem);
                captureStream.getTracks().forEach(track => track.stop());
                videoElem.remove();
                resolve(frame);
            }, 500);
        } catch (err) {
            reject(err);
        }
    })
}
export const capture = () => {
    const elemVideoPreview = document.createElement('VIDEO');
    elemVideoPreview.autoplay = true;
    return new Promise((resolve, reject) => {
        captureScreenShot(elemVideoPreview)
            .then(res => resolve(res))
            .catch(error => reject(error))
            .finally(() => elemVideoPreview.remove());
    })
}
/*
  | Base 64 to file maker
 */
export const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}
/*
  | Event bus for internal communication between components
 */
export const eventBus = new Vue();

