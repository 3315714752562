<template>
    <section class="o-mid-container">
        <div id="content-body">
            <div class="py-5 text-center w-100">
                <div class="mx-auto w-xxl w-auto-xs">
                    <div class="px-3">
                        <!-- Org List Login -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { mapActions,mapGetters,mapState } from 'vuex';
import Api from '../../../service/api';
import config from '../../../config/env';
// import { setTimeout } from 'timers';
import axios from 'axios'

export default {
    components: {
    },
    data () {
        return {
            site_id:'',
            token:'',        
        }
    },
    
    created() {
        //  console.log('route', this.$route.params.id,  this.$route.params.token);
         this.site_id=this.$route.params.id;
         this.token=this.$route.params.token;
        axios.post(config.VUE_APP_API_URL+'slogin/'+this.site_id,{}, {
            headers:{
                authorization: "Bearer " + this.token
            }
        }).then((response) => {
            // console.log(response.data.data);
            this.$store.commit('setToken', response.data.data);
            this.$router.push({name : 'dashboard'});
        }, (error) => {
            console.log(error);
        });
    },
    
    computed: {
        
    },

    mounted() {
    },

   
    methods: {
    }
}
</script>

