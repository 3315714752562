<template>
  <div id="app">
    <component :is="layout" :sidebar="sidebar" :header="header">
      <router-view/>
    </component>
    <HelpDeskWidget />
  </div>
</template>

<script>

  import HelpDeskWidget from "@/components/protected/HelpDesk/HelpDeskWidget.vue";

  const default_layout = "";

  export default {
    name: "App",
    metaInfo: {
      meta: [
        { charset: 'utf-8' },
      ]
    },
    components: {
      HelpDeskWidget
    },
    computed: {
      layout() {
        return (this.$route.meta.layout || default_layout) ;
      },
      header(){
        return (this.$route.name || '');
      },
      sidebar(){
        return (this.$route.meta.sidebar || '');
      }
      
    },

    created() {},

    updated() {
    }
  };
</script>
        
<style>
  @import "./assets/css/app.css"; 
  @import "./assets/css/style.css";
  @import url('https://fonts.googleapis.com/css2?family=Hurricane&display=swap');
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
div.swal2-container.swal2-backdrop-show{
  z-index: 99999999!important;
}
</style>