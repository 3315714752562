<template>
    <div class="single-view">
        <div class="app">
            <div class="row box-shadow-0" :class="header" role="main">
                <div class="col-2 sidenav modal-dialog dk white dark px-0">
                <!-- sidenav top -->
                    <div class="navbar lt single-nav">
                    <!-- brand -->
                    <a href="/" class="navbar-brand">
                            <span class="avatar w-100">
                                <img src="@/assets/images/logo-with-icon.png" alt="logo">
                            </span>
                    </a>
                    <!-- / brand -->
                    </div>
                </div>
                <header class="col-10 o-header dashboard px-0">
                    <div class="content-header white  box-shadow-0" id="content-header">
                        <div class="navbar navbar-expand-lg">

                            <!-- Page title -->
                            <div class="navbar-text nav-title flex toggle-btn" id="pageTitle"><a href="#"><img src="@/assets/images/toggle-icon.png" alt="toggle"></a></div>
                            <div class="navbar-text nav-title flex-1 pr-2" id="welcomeMsg">Hello {{loggedInUserName}}</div> 
                        </div>
                    </div>
                </header>
            </div>
            <router-view :key="$route.fullPath"></router-view> 
        </div>
        
    </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
    name: 'SingleVue',
    
    computed: {
        ...mapGetters({
          isExpendedSideBar: "isExpendedSideBar"
        })
    },

    data() {
        return {
            show_div : true,
            show_div_mobile : false,
            loggedInUserName: 'Test',
            center: { lat: null, lng: null },
        }
    },
    props: {
        header: {
            type: String
        },
        sidebar : {
            type : String
        }
    },
    mounted() {
        this.geolocate();
    },
    watch: {
        center: {
            handler: function (val, oldVal) {
                this.$store.dispatch("setLocationIdentifier", this.center);
            },
            deep: true
        },
    },
    methods: {
        geolocate: function() {
            navigator.geolocation.getCurrentPosition(position => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
            });

            this.$store.dispatch("setLocationIdentifier", this.center);
        },
    },
}
</script>

<style>
.single-nav {
    min-height: 4rem;
}
</style>