<script>
import BrowserCaptureImage from "./Screenshot/BrowserCaptureImage.vue";
import UploadCapturedImage from "./Screenshot/UploadCapturedImage.vue";
import {mapGetters, mapState} from "vuex";
import {userAgentBrowser} from "@/helpDesk";

export default {
  name: "HelpDeskCaptureOrUploadScreenshot",
  components: {
    UploadCapturedImage,
    BrowserCaptureImage
  },
  data() {
    return {
      userAgentString: null
    };
  },
  computed:{
    ...mapGetters({
      screenShot: 'outputScreenShot'
    }),
    IsBrowserCaptureSupported() {
      const {browserName, browserVersion} = userAgentBrowser();
      // Check browser supportability for "preferCurrentTab" at https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getDisplayMedia#browser_compatibility
      return (
          (browserName === 'chrome' && browserVersion >= 94) ||
          (browserName === "edge" && browserVersion >= 94) ||
          (browserName === 'opera' && browserVersion >= 80)
      );
    }
  },
  methods: {
    removeImageData() { this.$store.dispatch('removeHelpDeskFormScreenshot'); },
    enableEdit() {
      this.$store.commit('setHelpDeskFormEnableScreenshotEdit', true);
    }
  }
}
</script>

<template>
  <div>
    <div class="img-preview-area" v-if="screenShot">
      <div class="preview-image-wrapper">
        <img :src="screenShot" alt="Captured image" />
      </div>
      <button class="clear-image" type="button" @click.prevent="removeImageData">
        <i class="fa fa-trash" aria-hidden="true"></i>
      </button>
      <button class="highlight-or-hide" type="button" @click.prevent="enableEdit">
        <i class="fa fa-pencil-square" aria-hidden="true"></i>
        <span class="ml-3">Highlight or hide</span>
      </button>
    </div>
    <template v-if="IsBrowserCaptureSupported">
      <BrowserCaptureImage :visible="!screenShot" />
    </template>
    <template v-else>
      <UploadCapturedImage :visible="!screenShot" />
    </template>
  </div>
</template>

<style scoped>

</style>