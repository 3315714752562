import { extend, localize } from "vee-validate";
import { min, required, email, max_value, min_value } from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";

// Install rules
extend("required", required);
extend("min", min);
extend("email", email);
extend("max_value", max_value);
extend("min_value", min_value);

extend("staff_select", value => {
  if(value == 0){
    return 'The staff field is required';
  }
  return true;
})
extend("total_units", value => {
  if(value == 0){
    return 'Total units must be greater than zero';
  }
  return true;
})
extend("rate", value => {
  if(value == 0){
    return 'Rate must be greater than zero';
  }
  return true;
})

// Install messages
localize({
  en
});
