import Vue from "vue"
import Api from "@/service/api";
import _ from "lodash";
import httpErrorHandler from "@/httpErrorHandler";
import { downloadFile } from "@/service/helpers";

const state = {
    sample_files: [],
    sample_file_types: [
        {
            "value": "doc-to-excel",
            "name": "Document in Doc To Excel section"
        }, 
        {
            "value": "excel-to-doc-doc",
            "name": "Document in Excel To Doc section"

        },
        {
            "value": "excel-to-doc-excel",
            "name": "Excel in Excel To Doc section",
        },
        {
            "value": "case-doc-to-excel",
            "name": "Document in Case Document Doc To Excel section"
        },
        {
            "value": "case-excel-to-doc-doc",
            "name": "Document in Case Document Excel To Doc section"
        },
        {
            "value": "case-excel-to-doc-excel",
            "name": "Excel in Case Document Excel To Doc section"
            
        },
        {
            "value": "student-import-auth-data",
            "name": "Import Auth Data in Student section"
        },
        {
            "value": "import-student",
            "name": "Import Students in Student section"
        },
        {
            "value": "import-staff",
            "name": "Import Staff in Staff section"
        },
        {
            "value": "import-payroll",
            "name": "Import Payroll in Staff section"
        },
        {
            "value": "import-staff-checks",
            "name": "Import Staff Checks in Staff Salary section"
        },
        {
            "value": "import-document_label-settings",
            "name": "Import in Document Label Settings section"
        },
        {
            "value": "import-appointments",
            "name": "Import Sheet in Appointments section"
        },
        {
            "value": "import-merge-field",
            "name": "Import Merge Field in Merge Field section"
        },
        {
            "value": "import-session-data",
            "name": "Kareo File Import in Session Data Import section"
        },
        {
            "value": "import-goal",
            "name": "Mass Goal Import in Goal Import section"
        },
        {
            "value": "import-certification",
            "name": "Import csv in Import Certification section"
        },
        {
            "value": "import-service-line",
            "name": "Import csv in Import Service Line section"
        },
        {
            "value": "import-services",
            "name": "Import csv in Services section"
        },
        {
            "value": "import-skill-area",
            "name": "Import csv in Import Skill Area section"
        },
        {
            "value": "import-bcba-task",
            "name": "Import csv in Import BCBA Task section"
        },
        {
            "value": "note-helper-category-import",
            "name": "Import category in Note Helper Category section"
        },
        {
            "value": "school-import",
            "name": "Import school in Schools section"
        }
    ]
};
const mutations = {
    setSampleFiles(state, payload) {
        state.sample_files = payload;
    },
    setSampleFileTypes(state, payload) {
        state.sample_file_types = payload;
    }
};
const actions = {
    fetchSampleFiles(context){
        context.commit('setSampleFiles', []);
        Api.getAllSampleFiles()
            .then( response => {
                // context.commit('setSampleFiles', [...response.data.data]);
                if(response.data&&response.data.data){
                    context.commit('setSampleFiles', [...response.data.data]);
                }
            })
            .catch(e => { Vue.swal({ title: httpErrorHandler(e), type: "error" }); })
    },
    async fetchSampleFileTypeDownloadLink(context,type) {
        // if(type) {
            let tempObj = _.find(context.state.sample_files, ['type', type]);
            Api.getSampleFileDownloadUrl(tempObj.uuid)
            .then(async response => {
                // await downloadFile(response.data.url, 'Download');
                await Api.getSampleFileDownloadUrl(response.data.url);
            })
            .catch(e => { Vue.swal({ title: httpErrorHandler(e), type: "error" }); })
        // }

    },
    // async downloadSampleFile(context,url) {
        // if(type) {
            // let tempObj = _.find(context.state.sample_files, ['type', type]);
            // Api.downloadSampleFile(url)
            // .then(async response => {
            // await downloadFile(url, 'Download');
                // await Api.downloadSampleFile(url);
            // })
            // .catch(e => { Vue.swal({ title: httpErrorHandler(e), type: "error" }); })
        // }

    // }
};
const getters = {
    getAllSampleFiles: state => {
        return state.sample_files;
    },
    getAllSampleFileTypes: state => {
        return state.sample_file_types;
    },
    getSampleFilesByType:state => type => {
        let tempObj = _.find(state.sample_files, ['type', type]);
        return tempObj;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}